import { PrimitiveType, FormatXMLElementFn } from "intl-messageformat";
import { ReactNode, useCallback, useMemo } from "react";
import {
  useIntl,
  IntlFormatters,
  FormatRelativeTimeOptions,
  FormatListOptions,
} from "react-intl";

export interface UseFormattersReturnValue {
  /**
   * Formats a message using ICU message format (via Format.js).
   * Usage examples: https://formatjs.io/docs/react-intl/api#usage
   */
  formatMessage(
    descriptor: {
      description: string;
      defaultMessage: string;
    },
    values?: Record<
      string,
      PrimitiveType | FormatXMLElementFn<string | ReactNode>
    >
  ): string;

  /**
   * Formats a JS date using Intl APIs, using the currently-selected locale.
   */
  formatDate(
    value: Date,
    options?: Omit<Intl.DateTimeFormatOptions, "localeMatcher">
  ): string;

  /**
   * Formats a JS date using Intl APIs, using the currently-selected locale.
   */
  formatRelativeTime(
    value: number,
    unit?: Parameters<Intl.RelativeTimeFormat["format"]>[1],
    options?: FormatRelativeTimeOptions
  ): string;

  /**
   * Formats a number using Intl APIs, using the currently-selected locale.
   */
  formatNumber(value: number, options?: Intl.NumberFormatOptions): string;

  /**
   * Formats a list of elements using Intl APIs, using the currently-selected locale.
   */
  formatList(elements: string[], options?: FormatListOptions): string;
}

/**
 * Formatters format the given values according to the currently-selected
 * i18n locale (via context).
 */
export function useFormatters(): UseFormattersReturnValue {
  const intl = useIntl();

  const formatMessage = useCallback<UseFormattersReturnValue["formatMessage"]>(
    (...args) =>
      intl.formatMessage(
        ...(args as Parameters<IntlFormatters["formatMessage"]>)
      ),
    [intl]
  );

  const formatDate = useCallback<UseFormattersReturnValue["formatDate"]>(
    (...args) => intl.formatDate(...args),
    [intl]
  );

  const formatRelativeTime = useCallback<
    UseFormattersReturnValue["formatRelativeTime"]
  >((...args) => intl.formatRelativeTime(...args), [intl]);

  const formatNumber = useCallback<UseFormattersReturnValue["formatNumber"]>(
    (...args) =>
      intl.formatNumber(
        ...(args as Parameters<IntlFormatters["formatNumber"]>)
      ),
    [intl]
  );

  const formatList = useCallback<UseFormattersReturnValue["formatList"]>(
    (...args) =>
      intl.formatList(...(args as Parameters<IntlFormatters["formatList"]>)),
    [intl]
  );

  return useMemo(
    () => ({
      formatList,
      formatMessage,
      formatDate,
      formatRelativeTime,
      formatNumber,
    }),
    [formatList, formatMessage, formatDate, formatRelativeTime, formatNumber]
  );
}
