/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { AppBar, useMediaQuery } from "@material-ui/core";

import { AppTheme } from "@rewards-web/shared/style/theme";

import { Typography } from "../typography";
import { BackIcon } from "./back-icon";

export interface BackNavigationProps {
  height: number;
  maxContentWidth: number;
  fixedToTop?: boolean;
  title?: string;
  centerTitle?: boolean;
  onBackClicked?: () => void;
  variant?: "bar" | "button";
  color?: "primary" | "white";
}

export function BackNavigation({
  title,
  height,
  maxContentWidth,
  fixedToTop = true,
  variant = "bar",
  onBackClicked,
  centerTitle,
  color = "primary",
}: BackNavigationProps): JSX.Element {
  const theme = useTheme();
  const mobileOrTablet = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("md")
  );

  const content = (
    <div
      css={css`
        display: flex;
        align-items: center;

        ${theme.breakpoints.between("sm", "lg")} {
          padding-left: ${theme.spacing(3)};
        }

        ${variant === "bar" &&
        css`
          position: absolute;
        `}
      `}
    >
      {onBackClicked && (
        <button
          css={(theme: AppTheme) => css`
            // button reset:
            background: none;
            border: none;
            padding: 0;
            color: inherit;

            height: 100%;
            width: fit-content;
            padding-left: ${theme.spacing(2)};
            padding-right: ${theme.spacing(2)};
            cursor: pointer;
            display: flex;
            align-items: center;
          `}
          onClick={onBackClicked}
        >
          <BackIcon
            color={color === "primary" ? "white" : theme.palette.text.primary}
            css={css`
              font-size: 24px;
            `}
          />
          {title && !centerTitle && (
            <Typography
              color={
                variant === "button"
                  ? color === "primary"
                    ? "primary"
                    : "text.primary"
                  : undefined
              }
              css={(theme: AppTheme) =>
                css`
                  margin-left: ${theme.spacing(1)};
                `
              }
              variant="subtitle"
            >
              {title}
            </Typography>
          )}
        </button>
      )}
    </div>
  );

  return (
    <>
      {variant === "bar" ? (
        <AppBar
          css={(theme: AppTheme) => css`
            ${!fixedToTop &&
            css`
              position: absolute;
            `}
            background-color: ${color === "primary"
              ? theme.palette.primary.main
              : "white"};

            height: ${height}px;

            // padding-left should scale with the page:
            ${!mobileOrTablet &&
            css`
              padding-left: calc((100% - ${maxContentWidth + 32}px) / 2);
            `}

            color: ${color === "primary"
              ? "white"
              : theme.palette.text.primary};
          `}
          elevation={0}
        >
          <div
            css={css`
              display: flex;
              position: absolute;
              height: 100%;
              align-items: center;
              justify-content: space-between;
              margin: 0 auto;
              width: 100%;
            `}
          >
            {content}
            {title && centerTitle && (
              <Typography
                css={css`
                  margin: auto;
                `}
                variant="subtitle"
                color={color === "primary" ? "white" : "text.primary"}
              >
                {title}
              </Typography>
            )}
          </div>
        </AppBar>
      ) : (
        content
      )}
    </>
  );
}
