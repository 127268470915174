/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";

export function EVVTrackPageGoalDescription() {
  const theme = useTheme();

  return (
    <Typography
      variant="body"
      css={css`
        margin-top: ${theme.spacing(3)};
      `}
    >
      <FormattedMessage
        description="Goal track page > evv streak track description"
        defaultMessage="Clock in and out on time using the HHAeXchange app to achieve these goals. Shift data may take several days to reflect on this page."
      />

      <ul>
        <li>
          <FormattedMessage
            description="Goal track page > evv streak track description > evv rules > bullet 1"
            defaultMessage="Clock in within 15 mins of start time"
          />
        </li>

        <li>
          <FormattedMessage
            description="Goal track page > evv streak track description > evv rules > bullet 2"
            defaultMessage="Clock out within 15 mins of end time"
          />
        </li>

        <li>
          <FormattedMessage
            description="Goal track page > evv streak track description > evv rules > bullet 3"
            defaultMessage="Visit duration may vary up to 15 mins from the scheduled time"
          />
        </li>
      </ul>

      <FormattedMessage
        description="Goal track page > evv streak track description > contact us"
        defaultMessage="Something doesn't look right? <mailto>Contact us</mailto>."
        values={{
          mailto: (nodes) => (
            <a
              href={`mailto:${encodeURI("help@caribou.care")}`}
              css={css`
                color: ${theme.palette.primary.main};
              `}
            >
              {nodes}
            </a>
          ),
        }}
      />
    </Typography>
  );
}
