/** @jsxImportSource @emotion/react */
import { QueryResult } from "@apollo/client";
import { css } from "@emotion/react";
import { useState } from "react";

import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  Exact,
  RewardsOrganizationSignUpContactInfoPreference,
} from "@rewards-web/shared/graphql-types";
import { useQueryParam } from "@rewards-web/shared/hooks/use-query-param";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { Page } from "@rewards-web/shared/modules/page";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme, AppThemeProvider } from "@rewards-web/shared/style/theme";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { HeaderLogo } from "../../../shared/header-logo";
import { LanguagePage } from "../../authenticated/language";
import { useLanguageSelector } from "../hooks/use-language-selector";
import { UnauthenticatedLanguageSelector } from "../language-selector";
import { ConfirmationPage } from "./confirmation-page";
import { OptInForm } from "./opt-in-form";
import { PrivacyLockIcon } from "./opt-in-form/privacy-lock-icon";
import { useOptInPageDataForOrganizationQuery } from "./opt-in-page-data-for-organization.generated";
import {
  OptInPageDataForWorkEmailQuery,
  useOptInPageDataForWorkEmailQuery,
} from "./opt-in-page-data-for-work-email.generated";
import { useOptInWithPersonalInformationMutation } from "./submit-opt-in-information.generated";

const MAX_CONTENT_WIDTH = 600;

export function OptInPage(): JSX.Element {
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const {
    localeName,
    setHasUserUpdatedLangaugeForLogin,
  } = useLanguageSelector();
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const snackbar = useSnackbar();
  const [submitOptInApplication] = useOptInWithPersonalInformationMutation();
  const [completeApplication, setCompleteApplication] = useState(false);

  const [preFilledWorkEmail] = useQueryParam("work_email");
  const [organizationId] = useQueryParam("org");

  const dataForWorkEmail = useOptInPageDataForWorkEmailQuery({
    skip: !preFilledWorkEmail,
    onError: reportError,
    variables: { workEmail: preFilledWorkEmail! },
  });

  const dataForOrganization = useOptInPageDataForOrganizationQuery({
    skip: !organizationId,
    onError: reportError,
    variables: { organizationId: organizationId! },
  });

  const query = preFilledWorkEmail ? dataForWorkEmail : dataForOrganization;

  const onSubmitOptIn = async (
    workEmail: string,
    personalEmail: string,
    personalPhoneNumber: string
  ) => {
    try {
      const res = await submitOptInApplication({
        variables: {
          workEmail: workEmail,
          personalEmail: personalEmail,
          personalPhoneNumber: personalPhoneNumber,
        },
      });
      setCompleteApplication(true);
      track("Opt in form submitted", {
        linkType: (() => {
          if (preFilledWorkEmail) {
            return "personalized";
          }

          if (organizationId) {
            return "generic_for_organization";
          }

          return "generic";
        })(),
        requestId: (res.context as ApolloGraphQLResponseContext).requestId,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Opt in page > unexpected error occurred opting in",
          defaultMessage:
            "An unexpected error occurred. Please try again later.",
        }),
      });
    }
  };

  if (query.loading && !query.data) {
    return <PageLoadingState />;
  }

  const whiteLabelConfig = query.data?.organization?.whiteLabelConfig;

  const rewardsProgramName = whiteLabelConfig
    ? whiteLabelConfig.rewardsProgramShortName
    : "Caribou Rewards";

  const agencyFaviconUrl = whiteLabelConfig
    ? whiteLabelConfig.faviconImageUrl
    : undefined;

  const optInPromo = preFilledWorkEmail
    ? (query as QueryResult<
        OptInPageDataForWorkEmailQuery,
        Exact<{
          workEmail: string;
        }>
      >).data?.getRewardsOrganizationOptInPromo
    : null;

  return (
    <AppThemeProvider
      theme={
        whiteLabelConfig?.primaryColor && whiteLabelConfig?.secondaryColor
          ? {
              palette: {
                primary: {
                  main: whiteLabelConfig.primaryColor,
                },
                secondary: {
                  main: whiteLabelConfig.secondaryColor,
                },
              },
            }
          : {}
      }
    >
      <Page
        faviconUrl={agencyFaviconUrl}
        browserTitle={formatMessage(
          {
            description: "Opt in page > browser title",
            defaultMessage: "{rewards_program_name} Sign up",
          },
          {
            rewards_program_name: rewardsProgramName,
          }
        )}
        analyticsPageName="SignUp"
      >
        {showLanguageSelector && (
          <LanguagePage
            pageType={{
              type: "fullscreen_modal",
              onBackClicked: () => {
                setShowLanguageSelector(false);
              },
              onLocaleUpdated: () => {
                setHasUserUpdatedLangaugeForLogin(true);
                setShowLanguageSelector(false);
              },
            }}
          />
        )}

        {!showLanguageSelector && (
          <div
            css={css`
              height: 100%;
              background-color: white;
              overflow-y: scroll;
              display: flex;
              flex-direction: column;
            `}
          >
            <HeaderLogo
              organizationLogoImageUrl={
                query.data?.organization?.logoImageUrl ?? null
              }
              whiteLabelConfig={whiteLabelConfig}
            />

            <div
              css={(theme: AppTheme) => css`
                width: calc(100% - ${theme.spacing(4)});
                max-width: ${MAX_CONTENT_WIDTH}px;
                margin: 0 auto;
                padding-bottom: ${theme.spacing(4)};
                flex-grow: 1;
              `}
            >
              {completeApplication ? (
                <ConfirmationPage
                  pointsEarnedForOptingIn={
                    optInPromo?.pointsEarnedForOptingIn ?? null
                  }
                  whiteLabelledTitle={whiteLabelConfig?.rewardsProgramShortName}
                />
              ) : (
                <OptInForm
                  onSubmit={onSubmitOptIn}
                  preFilledWorkEmail={preFilledWorkEmail}
                  whiteLabelledTitle={whiteLabelConfig?.rewardsProgramShortName}
                  disclaimer={
                    query.data?.organization?.optInPageDisclaimer ?? null
                  }
                  isWorkOrPersonalInfo={
                    query.data?.organization?.signUpContactInfoPreference ===
                    RewardsOrganizationSignUpContactInfoPreference.WorkOrPersonal
                  }
                />
              )}
            </div>

            <div
              css={(theme: AppTheme) => css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding-top: ${theme.spacing(4)};
                padding-bottom: ${theme.spacing(2)};
              `}
            >
              <Typography
                color="textPrimary"
                variant="footnote"
                css={css`
                  max-width: 360px;
                  @media (max-width: 600px) {
                    max-width: 100%;
                    text-align: center;
                  }
                `}
              >
                <FormattedMessage
                  description="Opt in page > privacy policy and terms message"
                  defaultMessage="<privacypolicy>Privacy Policy</privacypolicy> | <terms>Terms and Conditions</terms>"
                  values={{
                    privacypolicy: (nodes) => (
                      <Typography
                        variant="footnote"
                        component="a"
                        href="https://www.caribou.care/privacy-policy-rewards"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {nodes}
                      </Typography>
                    ),
                    terms: (nodes) => (
                      <Typography
                        variant="footnote"
                        component="a"
                        href="https://www.caribou.care/terms-rewards"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {nodes}
                      </Typography>
                    ),
                  }}
                />
              </Typography>

              <div
                css={(theme: AppTheme) => css`
                  display: flex;
                  align-items: center;
                  margin-top: ${theme.spacing(2)};
                `}
              >
                <PrivacyLockIcon
                  css={(theme: AppTheme) => css`
                    margin-right: ${theme.spacing(1)};
                  `}
                />
                <Typography color="textSecondary" variant="footnote">
                  <FormattedMessage
                    description="Opt in page > we respect your privacy line"
                    defaultMessage="We respect your privacy."
                  />
                </Typography>
              </div>
            </div>

            <Divider
              css={css`
                width: 90%;
                margin-left: 2.5%;
              `}
            />

            <UnauthenticatedLanguageSelector
              localeName={localeName}
              setShowLanguageSelector={setShowLanguageSelector}
            />
          </div>
        )}
      </Page>
    </AppThemeProvider>
  );
}
