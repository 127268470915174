/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";

import { useTrack } from "@rewards-web/shared/modules/analytics";

import { Tab } from "./tab";

export interface SubPageNavigationTabsProps {
  tabs: Array<{
    label: string;
    path: string;
    state?: Record<string, any>;
  }>;
  hostPageName: string;
  onTabClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function SubPageNavigationTabs(props: SubPageNavigationTabsProps) {
  const location = useLocation();
  const track = useTrack();

  return (
    <div
      css={css`
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      `}
    >
      {props.tabs.map(({ label, path, state }) => {
        return (
          <div
            key={path}
            css={css`
              flex: 1;
            `}
          >
            <Tab
              label={label}
              selected={path === location.pathname}
              to={path}
              state={state}
              onClick={(event) => {
                if (props.onTabClick) {
                  props.onTabClick(event);
                } else {
                  track(`${props.hostPageName} tab clicked`, {
                    path,
                  });
                }
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
