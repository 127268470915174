import { differenceInDays, startOfDay } from "date-fns";
import { ReactNode } from "react";

import {
  DependingGoalUnlockCriteria,
  Goal,
  GoalStatus,
  Maybe,
  TimeBasedlUnlockCriteria,
} from "@rewards-web/shared/graphql-types";
import { shouldBeNever } from "@rewards-web/shared/lib/should-be-never";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalIcon } from "../goal-icon";
import { useFormattedGoalTitle } from "../use-formatted-goal-title";
import { BaseGoalCardProps } from "./base-goal-card";
import { GoalPillProps } from "./base-goal-card/goal-card-pill";
import { GoalCardFragmentFragment } from "./goal-card-fragment.generated";

export function useCommonBaseGoalCardProps({
  goal,
  cardContext,
  pathToTrack,
  lockedDetailModalTextOverride,
}: {
  goal: GoalCardFragmentFragment;
  cardContext: "home" | "subpage";
  pathToTrack?: string;
  lockedDetailModalTextOverride?(
    unlockCriteria:
      | ({ __typename: "DependingGoalUnlockCriteria" } & Pick<
          DependingGoalUnlockCriteria,
          "__typename"
        >)
      | ({ __typename: "TimeBasedlUnlockCriteria" } & Pick<
          TimeBasedlUnlockCriteria,
          "unlocksAt"
        >)
  ): ReactNode;
}): Pick<
  BaseGoalCardProps,
  | "icon"
  | "analyticsData"
  | "status"
  | "rewardConfig"
  | "topRightPillProps"
  | "detailButtonConfig"
  | "title"
> {
  const topRightPillProps = useTopRightPillProps({ goal });
  const title = useFormattedGoalTitle(goal);
  const { formatMessage } = useFormatters();

  const dependingGoalTitle = useFormattedGoalTitle(
    goal.unlockCriteria?.__typename === "DependingGoalUnlockCriteria"
      ? goal.unlockCriteria.dependingGoal
      : null
  );

  return {
    icon: <GoalIcon goalType={goal.type} />,
    analyticsData: {
      goalId: goal.id,
      goalType: goal.type,
      goalStatus: goal.status,
      goalExpiry: goal.expiresAt,
      cardContext,
    },
    status: (() => {
      switch (goal.status) {
        case GoalStatus.Available:
          return "available";
        case GoalStatus.Achieved:
          return "achieved";
        case GoalStatus.Locked:
        default:
          return "locked";
      }
    })(),
    rewardConfig: {
      points: {
        numPoints: goal.numPoints ?? undefined,
        labelType: "default",
      },
    },
    topRightPillProps,
    title,

    detailButtonConfig: ((): BaseGoalCardProps["detailButtonConfig"] => {
      if (goal.status === GoalStatus.Locked && goal.unlockCriteria) {
        if (lockedDetailModalTextOverride) {
          const override = lockedDetailModalTextOverride(goal.unlockCriteria);

          if (override) {
            return {
              type: "modal",
              contentsText: override,
            };
          }
        }

        // by default,
        // locked goals should show a modal with some default copy
        // about how the goal should be unlocked.

        switch (goal.unlockCriteria?.__typename) {
          case "TimeBasedlUnlockCriteria":
            return {
              type: "modal",
              contentsText: formatMessage(
                {
                  description:
                    "Goal card > locked goal modal text unlocks on date",
                  defaultMessage: "This goal unlocks in {num_days} days.",
                },
                {
                  num_days: getGoalUnlocksInDays(goal.unlockCriteria.unlocksAt),
                }
              ),
            };
          case "DependingGoalUnlockCriteria":
            return {
              type: "modal",
              contentsText: formatMessage(
                {
                  description:
                    "Goal card > locked goal modal text unlocks when depending goal achieved",
                  defaultMessage:
                    "Unlock this goal by completing <bold>{depending_goal_title}</bold>.",
                },
                {
                  depending_goal_title: dependingGoalTitle!,
                  bold: (nodes) => <strong>{nodes}</strong>,
                }
              ),
            };
          case undefined:
            // shouldn't happen - locked goals should have criteria
            return undefined;
          default:
            shouldBeNever(goal.unlockCriteria);
        }

        return undefined;
      }

      if (cardContext === "home" && pathToTrack) {
        return { type: "link", path: pathToTrack };
      }
    })(),
  };
}

function useTopRightPillProps({
  goal,
}: {
  goal: Pick<Goal, "status" | "expiresAt"> & {
    unlockCriteria?: Maybe<
      | ({ __typename: "DependingGoalUnlockCriteria" } & Pick<
          DependingGoalUnlockCriteria,
          "__typename"
        >)
      | ({ __typename: "TimeBasedlUnlockCriteria" } & Pick<
          TimeBasedlUnlockCriteria,
          "unlocksAt"
        >)
    >;
  };
}): GoalPillProps | undefined {
  const { formatMessage } = useFormatters();
  switch (goal.status) {
    case GoalStatus.Locked:
      return goal.unlockCriteria?.__typename === "TimeBasedlUnlockCriteria"
        ? {
            text: formatMessage(
              {
                defaultMessage:
                  "In {unlocks_in_days} {unlocks_in_days, plural, one {day} other {days}}",
                description: "Goal card > expires in days",
              },
              {
                unlocks_in_days: getGoalUnlocksInDays(
                  goal.unlockCriteria.unlocksAt
                ),
              }
            ),
            variant: "dark",
          }
        : undefined;
    case GoalStatus.Available:
      return goal.expiresAt
        ? {
            text: formatMessage(
              {
                defaultMessage:
                  "{expires_in_days} {expires_in_days, plural, one {day} other {days}} left",
                description: "Goal card > expires in days",
              },
              {
                expires_in_days: differenceInDays(
                  goal.expiresAt,
                  startOfDay(new Date())
                ),
              }
            ),
            variant: "light",
          }
        : undefined;
    case GoalStatus.Achieved:
      return {
        text: formatMessage({
          defaultMessage: "Complete",
          description: "Goal card > complete",
        }),
        variant: "tertiary",
      };
    default:
      return undefined;
  }
}

export function getGoalUnlocksInDays(unlocksAt: Date) {
  return Math.max(differenceInDays(unlocksAt, startOfDay(new Date())), 0);
}
