/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface GoalPillProps {
  text: string;
  variant: "light" | "dark" | "warm" | "tertiary";
  loading?: boolean;
}

export function GoalCardPill({ text, variant, loading }: GoalPillProps) {
  if (loading) {
    return <Skeleton height="22px" width="100px" animated />;
  }
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        padding: 0 ${theme.spacing(0.75)};
        border-radius: 8px;
        background-color: ${(() => {
          switch (variant) {
            case "dark":
              return "#636666";
            case "tertiary":
              return theme.palette.tertiary.main;
            case "warm":
              return theme.palette.grey[200];
            case "light":
            default:
              return theme.palette.background.default;
          }
        })()};
        user-select: none;
        padding: 1px ${theme.spacing(1.25)};
      `}
    >
      <Typography
        variant="caption"
        fontWeight={500}
        color={["dark", "tertiary"].includes(variant) ? "white" : "textPrimary"}
      >
        {text}
      </Typography>
    </div>
  );
}
