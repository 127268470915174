import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EvvTrackPageVisitDataQueryVariables = Types.Exact<{
  startDateGte: Types.Scalars['Timestamp'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type EvvTrackPageVisitDataQuery = (
  { __typename?: 'Query' }
  & { listMyVisitsAsOfStartDate: (
    { __typename?: 'ListMyVisitsAsOfStartDateResponse' }
    & { items: Array<(
      { __typename?: 'Visit' }
      & Pick<Types.Visit, 'id' | 'startAt' | 'endAt'>
    )> }
  ) }
);


export const EvvTrackPageVisitDataDocument = gql`
    query EVVTrackPageVisitData($startDateGte: Timestamp!, $limit: Int) {
  listMyVisitsAsOfStartDate(page: {startDateGte: $startDateGte, limit: $limit}) {
    items {
      id
      startAt
      endAt
    }
  }
}
    `;

/**
 * __useEvvTrackPageVisitDataQuery__
 *
 * To run a query within a React component, call `useEvvTrackPageVisitDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvvTrackPageVisitDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvvTrackPageVisitDataQuery({
 *   variables: {
 *      startDateGte: // value for 'startDateGte'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEvvTrackPageVisitDataQuery(baseOptions: Apollo.QueryHookOptions<EvvTrackPageVisitDataQuery, EvvTrackPageVisitDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvvTrackPageVisitDataQuery, EvvTrackPageVisitDataQueryVariables>(EvvTrackPageVisitDataDocument, options);
      }
export function useEvvTrackPageVisitDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvvTrackPageVisitDataQuery, EvvTrackPageVisitDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvvTrackPageVisitDataQuery, EvvTrackPageVisitDataQueryVariables>(EvvTrackPageVisitDataDocument, options);
        }
export type EvvTrackPageVisitDataQueryHookResult = ReturnType<typeof useEvvTrackPageVisitDataQuery>;
export type EvvTrackPageVisitDataLazyQueryHookResult = ReturnType<typeof useEvvTrackPageVisitDataLazyQuery>;
export type EvvTrackPageVisitDataQueryResult = Apollo.QueryResult<EvvTrackPageVisitDataQuery, EvvTrackPageVisitDataQueryVariables>;