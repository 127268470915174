/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";

import { GoalStatus } from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalCardProps } from "..";
import { BaseGoalCard } from "../base-goal-card";
import { useCommonBaseGoalCardProps } from "../lib";
import { useJobShareGoalCardDataQuery } from "./job-share-goal-card-data.generated";

export function ShareJobGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage } = useFormatters();

  const { data, loading: dataLoading } = useJobShareGoalCardDataQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });

  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/share-job",
    lockedDetailModalTextOverride: () => {
      return (
        <>
          {formatMessage({
            description: "Goal card > share job > locked detail modal one",
            defaultMessage:
              "This goal unlocks at the start of every month. Make sure to share any remaining jobs you have in the current month to earn points!",
          })}
          <br /> <br />
          {formatMessage(
            {
              description: "Goal card > share job > locked detail modal two",
              defaultMessage:
                "Don't forget - you can still <link>share jobs</link> after you run out of job shares. You won't earn points but can earn if your referral gets hired.",
            },
            {
              link: (nodes) => <Link to="/jobs">{nodes}</Link>,
            }
          )}
        </>
      );
    },
  });

  const maxEarnableDollars: string | null = (() => {
    if (!data) {
      return null;
    }
    const {
      maxPointsEarnedPerCandidate,
      pointsPerDollar,
    } = data.getMyRewardsOrganization;
    return formatDollars(
      (maxPointsEarnedPerCandidate + (goal.numPoints ?? 0)) / pointsPerDollar
    );
  })();

  return (
    <BaseGoalCard
      {...commonBaseGoalCardProps}
      detailText={formatMessage({
        description: "Goal card > share job > detail text",
        defaultMessage: "Help a friend while helping more patients get care",
      })}
      primaryButtonProps={
        goal.status === GoalStatus.Available
          ? {
              label: formatMessage({
                description: "Goal card > share job > button label",
                defaultMessage: "Share job",
              }),
              linkTo: "/jobs",
            }
          : undefined
      }
      pointsPillProps={{
        text: formatMessage(
          {
            description: "Goal card > share job > total possible points",
            defaultMessage: `Up to {max_earnable_dollars} if hired!`,
          },
          { max_earnable_dollars: maxEarnableDollars }
        ),
        variant: "warm",
        loading: dataLoading,
      }}
    />
  );
}
