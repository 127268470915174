/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";

import { useRefHeight } from "@rewards-web/shared/hooks/use-ref-height";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { getMilestoneRewardCompletePercentage } from "../../../shared/milestone-rewards-offer/lib";
import { PageContentContainer } from "../../../shared/page-content-container";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { MilestoneRewardsContent } from "./milestone-rewards-content";
import { MilestoneRewardsPageFooter } from "./milestone-rewards-footer";
import { useGetMilestoneRewardsForSubPageQuery } from "./milestones-page-data.generated";

export function MilestoneRewardsPage(): JSX.Element {
  const { formatMessage } = useFormatters();
  const track = useTrack();
  const { ref: footerRef, height: footerHeight } = useRefHeight({
    isReady: true,
  });
  const query = useGetMilestoneRewardsForSubPageQuery({
    onError: reportError,
  });

  const milestoneRewardsOffer = query.data?.milestoneRewardsSubPageData ?? null;

  const trackingProperties = useMemo(
    () => ({
      offerCompleted: milestoneRewardsOffer?.allMilestonesComplete,
      mustCompleteEVV: milestoneRewardsOffer?.mustCompleteEVV,
      currentMilestoneId: milestoneRewardsOffer?.currentMilestoneStep?.id,
      currentMilestoneProgress:
        milestoneRewardsOffer?.currentMilestoneStep?.progress,
      currentMilestoneCompletionPercent: getMilestoneRewardCompletePercentage(
        milestoneRewardsOffer?.currentMilestoneStep
      ),
      nextMilestoneId: milestoneRewardsOffer?.nextMilestoneStep?.id,
    }),
    [milestoneRewardsOffer]
  );

  useEffect(() => {
    if (milestoneRewardsOffer) {
      track("Viewed milestone rewards offer page", trackingProperties);
    }
  }, [milestoneRewardsOffer, track, trackingProperties]);

  return (
    <div
      css={(theme: AppTheme) => css`
        height: 100%;
        overflow-y: scroll;
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <SubPageHeader
        pageName={formatMessage({
          description: "Milestone rewards offer page > navigation banner title",
          defaultMessage: "Milestone Rewards",
        })}
        analyticsPageName="Milestone Rewards Offer"
        backTo="rewards"
        color="white"
        css={css`
          position: relative;
          z-index: 1;
        `}
      />

      <PageContentContainer
        css={css`
          width: 100%;
          padding-top: 0;
          padding-bottom: ${footerHeight}px;
        `}
      >
        <MilestoneRewardsContent userMilestoneRewardsQuery={query} />
      </PageContentContainer>
      <MilestoneRewardsPageFooter footerRef={footerRef} />
    </div>
  );
}
