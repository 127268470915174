/** @jsxImportSource @emotion/react */
import { useMemo } from "react";

import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { SubPageHeader } from "../../../../shared/sub-page-header";
import { usePastRedemptionsDataQuery } from "../../points-summary/past-redemptions/query.generated";

export function PointsSummaryHeader() {
  const { formatMessage } = useFormatters();
  const { data, loading } = usePastRedemptionsDataQuery({
    fetchPolicy: "cache-first",
  });

  const pageName = useMemo(() => {
    if (loading) {
      return "";
    }
    const totalAmount = data?.getMyRewardsUserRedemptions.items.reduce(
      (sum, redemption) => sum + redemption.dollars,
      0
    );
    if (totalAmount) {
      return formatMessage(
        {
          description: "Points summary page > redemptions page title",
          defaultMessage:
            "You've redeemed <strong>{total_amount}</strong> so far!",
        },
        {
          total_amount: formatDollars(totalAmount || 0),
          strong: (nodes) => <strong>{nodes}</strong>,
        }
      );
    }
    return formatMessage({
      description: "Points summary page > no redemptions page title",
      defaultMessage: "Points Summary",
    });
  }, [data, loading, formatMessage]);

  return (
    <SubPageHeader
      pageName={pageName}
      analyticsPageName="Points Summary"
      fixedBackNavigation={true}
      color="white"
    />
  );
}
