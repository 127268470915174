/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useEffect, useState } from "react";

import { BackNavigation } from "@rewards-web/shared/components/back-navigation";
import { Card } from "@rewards-web/shared/components/card";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  useFormatters,
  useLocale,
} from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../shared/page-content-container";
import { RewardsLocaleSelector } from "../../../shared/rewards-locale-selector";
import {
  SubPageHeader,
  SubPageHeaderProps,
} from "../../../shared/sub-page-header";
import { useSetMyRewardsUserLocaleMutation } from "./mutation.generated";

const BACK_NAVIGATION_BAR_HEIGHT = 60;
const MAX_CONTENT_WIDTH = 790;

/**
 * LanguagePage component can either be rendered on its own page (i.e. for authenticated flow),
 * or be rendereded as a modal (i.e for unauthenticated flow).
 */
export interface LanguagePageProps {
  pageType:
    | {
        type: "sub_page";
        analyticsPageName: string;
        backTo: SubPageHeaderProps["backTo"];
      }
    | {
        type: "fullscreen_modal";
        onBackClicked(): void;
        onLocaleUpdated(): void;
      };
}

export function LanguagePage({ pageType }: LanguagePageProps) {
  const { formatMessage } = useFormatters();
  const theme = useTheme();
  const snackbar = useSnackbar();
  const { locale } = useLocale();
  const track = useTrack();
  const [snackbarLocale, setSnackbarLocale] = useState<string>();

  const [mutate, { loading, data }] = useSetMyRewardsUserLocaleMutation({
    onError: (error) => {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Language settings page > set locale error snackbar",
          defaultMessage: "Something went wrong",
        }),
      });
    },
  });

  useEffect(() => {
    if (
      data &&
      data.setMyRewardsUserLocale.locale === locale &&
      snackbarLocale !== locale
    ) {
      setSnackbarLocale(locale);
      snackbar.show({
        severity: "success",
        message: formatMessage({
          description: "Language settings page > set locale success snackbar",
          defaultMessage: "Language has been updated",
        }),
      });
    }
  }, [locale, data, formatMessage, snackbar, snackbarLocale]);

  return (
    <>
      {pageType.type === "sub_page" && (
        <SubPageHeader
          pageName={formatMessage({
            description: "Language settings page > page title",
            defaultMessage: "Language",
          })}
          analyticsPageName={pageType.analyticsPageName}
          backTo={pageType.backTo}
        />
      )}

      {pageType.type === "fullscreen_modal" && (
        <>
          <BackNavigation
            height={BACK_NAVIGATION_BAR_HEIGHT}
            maxContentWidth={MAX_CONTENT_WIDTH}
            title={formatMessage({
              defaultMessage: "Language",
              description: "Language settings page > page title",
            })}
            onBackClicked={pageType.onBackClicked}
          />

          <div
            css={css`
              margin-top: ${BACK_NAVIGATION_BAR_HEIGHT}px;
            `}
          />
        </>
      )}

      <PageContentContainer>
        <Card
          css={(appTheme: AppTheme) => css`
            padding: ${appTheme.spacing(2.5)};
          `}
        >
          <RewardsLocaleSelector
            onLocaleChange={(locale) => {
              if (pageType.type === "fullscreen_modal") {
                pageType.onLocaleUpdated();
                track("User updated language", { locale });
              } else {
                mutate({ variables: { locale } });
              }
            }}
            borderWidth={1}
            borderColor={theme.palette.divider}
            isDisabled={loading}
          />
        </Card>
      </PageContentContainer>
    </>
  );
}
