/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface RedeemPointsDrawerGiftcardBodyProps {
  email: string | undefined | null;
}

export function RedeemPointsDrawerGiftcardBody({
  email,
}: RedeemPointsDrawerGiftcardBodyProps) {
  const { formatMessage } = useFormatters();
  return (
    <>
      {email && !email.endsWith("@example.com") && (
        <Typography
          css={(appTheme: AppTheme) =>
            css`
              margin-bottom: ${appTheme.spacing(1.5)};
              width: 100%;
              padding: ${appTheme.spacing(2.5)};
            `
          }
          variant="body"
        >
          {formatMessage(
            {
              defaultMessage:
                "A link to choose your gift card will be sent to: <bordered_email>{email}</bordered_email>",
              description: "Redeem points drawer > giftcard email note",
            },
            {
              email,
              bordered_email: (node) => (
                <Typography
                  variant="subtitle"
                  color="primary"
                  css={(appTheme: AppTheme) => css`
                    width: 100%;
                    border: 1px solid ${appTheme.palette.grey[400]};
                    margin-top: ${appTheme.spacing(2.5)};
                    padding: ${appTheme.spacing(2.5)};
                    text-align: center;
                    border-radius: 10px;
                  `}
                >
                  <FontAwesomeIcon
                    css={(appTheme: AppTheme) =>
                      css`
                        margin-right: ${appTheme.spacing(1.25)};
                      `
                    }
                    icon={faEnvelope}
                  />
                  <span
                    css={css`
                      overflow-wrap: break-word;
                    `}
                  >
                    {email}
                  </span>
                </Typography>
              ),
            }
          )}
        </Typography>
      )}
      <Typography variant="body">
        {formatMessage({
          defaultMessage:
            "Redemptions may take up to 3 business days to arrive. Applicable taxes will apply.",
          description: "Redeem points drawer > giftcard redemptions days note",
        })}
      </Typography>
    </>
  );
}
