/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { AppStore } from "../../../graphics/app-store";
import googlePlayImageUrl from "../../../graphics/play-store.png";

export interface EVVStreakGoalCardHHAXModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function EVVStreakGoalCardHHAXModal({
  isOpen,
  onClose,
}: EVVStreakGoalCardHHAXModalProps) {
  return (
    <ResponsiveDialog open={isOpen} onClose={onClose} maxWidth="500px">
      <div
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            width: 100%;
          `}
        >
          <button
            css={css`
              border-radius: 50%;
              border-width: 0px;
              height: 27px;
              width: 27px;
              background-color: #e0e0e0;
              padding: 0;
              cursor: pointer;
            `}
            onClick={onClose}
          >
            <CloseOutlinedIcon
              css={css`
                padding-top: 3px;
                font-size: 1.6em;
                color: #757575;
              `}
            />
          </button>
        </div>

        <Typography
          css={(theme: AppTheme) => css`
            padding-bottom: ${theme.spacing(4)};
            text-align: center;
          `}
          variant="h5"
          color="text.primary"
        >
          <FormattedMessage
            description="Goal card > evv streak login modal > title"
            defaultMessage="HHAeXchange app"
          />
        </Typography>

        <Typography
          css={(theme: AppTheme) => css`
            padding-bottom: ${theme.spacing(4)};
            text-align: center;
          `}
          variant="body"
          color="text.primary"
        >
          <FormattedMessage
            defaultMessage="To accomplish this goal, you will need to go to your HHAeXchange app."
            description="Goal card > evv streak login modal > description"
          />
        </Typography>

        <div
          css={(appTheme: AppTheme) => css`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: ${appTheme.spacing(2)};
            row-gap: ${appTheme.spacing(1)};
            margin-bottom: ${appTheme.spacing(1)};
          `}
        >
          <a
            href="https://apps.apple.com/us/app/hhaexchange/id883673336"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AppStore height={50} />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.hhaexchange.caregiver"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              css={css`
                height: 51px;
              `}
              src={googlePlayImageUrl}
              alt="Google Play"
            />
          </a>
        </div>
      </div>
    </ResponsiveDialog>
  );
}
