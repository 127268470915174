import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type GoalAchievedModalFragmentFragment = (
  { __typename?: 'Goal' }
  & Pick<Types.Goal, 'id' | 'type' | 'numPoints' | 'numCaribouSponsoredDrawTickets' | 'achievedAt'>
  & { goalTracker: { __typename: 'JobShareGoalTracker' } | { __typename: 'SurveyGoalTracker' } | (
    { __typename: 'EVVStreakGoalTracker' }
    & Pick<Types.EvvStreakGoalTracker, 'id' | 'targetStreak'>
  ) | { __typename: 'WatchVideoGoalTracker' } | { __typename: 'HHAXLoginGoalTracker' } }
);

export const GoalAchievedModalFragmentFragmentDoc = gql`
    fragment GoalAchievedModalFragment on Goal {
  id
  type
  numPoints
  numCaribouSponsoredDrawTickets
  achievedAt
  goalTracker {
    ... on EVVStreakGoalTracker {
      id
      targetStreak
    }
    __typename
  }
}
    `;