/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ProgressBar } from "../../../../progress-bar";

export interface GoalCardProgressBarProps {
  value: number;
  max: number;
  easingAlgorithm?: "linear" | "ease-out-in";
  getUnitsLabel?: (params: { value: number; max: number }) => string;
}

export function GoalCardProgressBar({
  value,
  max,
  getUnitsLabel,
  easingAlgorithm,
}: GoalCardProgressBarProps) {
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        min-width: 100%;
        margin: ${theme.spacing(1)} 0;
        align-items: center;
        gap: ${theme.spacing(1.5)};
        ${!getUnitsLabel &&
        css`
          flex: 1;
          @media (min-width: 550px) {
            min-width: 250px;
            width: 100%;
          }
        `}
      `}
    >
      <ProgressBar
        max={max}
        value={value}
        height="8px"
        color="primary"
        easingAlgorithm={easingAlgorithm}
      />
      {getUnitsLabel && (
        <Typography
          variant="caption"
          css={(appTheme: AppTheme) => css`
            text-transform: uppercase;
            margin-left: auto;
            margin-right: ${appTheme.spacing(0.25)};
            text-align: end;
            white-space: nowrap;
            margin-bottom: 1px;
          `}
        >
          {getUnitsLabel({
            value,
            max,
          })}
        </Typography>
      )}
    </div>
  );
}
