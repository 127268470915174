/** @jsxImportSource @emotion/react */
import { useState } from "react";

import {
  EvvStreakGoalTracker,
  GoalStatus,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalCardProps } from "..";
import { BaseGoalCard } from "../base-goal-card";
import { useCommonBaseGoalCardProps } from "../lib";
import { EVVStreakGoalCardHHAXModal } from "./hhax-modal";

export function EvvStreakGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage } = useFormatters();
  const [open, setOpen] = useState(false);

  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/evv",
  });

  const {
    targetStreak,
    currentStreak,
  } = goal.goalTracker as EvvStreakGoalTracker;

  return (
    <>
      <EVVStreakGoalCardHHAXModal
        isOpen={open}
        onClose={() => setOpen(false)}
      />
      <BaseGoalCard
        {...commonBaseGoalCardProps}
        progressProps={
          targetStreak > 1
            ? {
                max: targetStreak,
                value: currentStreak ?? 0,
                getUnitsLabel: ({ max, value }) =>
                  formatMessage(
                    {
                      defaultMessage: "{value, number}/{max, number} shifts",
                      description: "Goal card > evv streak > progress units",
                    },
                    { max, value }
                  ),
              }
            : undefined
        }
        detailText={
          targetStreak === 1
            ? formatMessage({
                defaultMessage:
                  "Use the HHAeXchange app to clock in and out of your next visit",
                description: "Goal card > evv streak of 1 > details text",
              })
            : formatMessage(
                {
                  defaultMessage:
                    "Use the HHAeXchange app {num_target_streak} {num_target_streak, plural, one {time} other {times}} in a row",
                  description: "Goal card > evv streak > details text",
                },
                {
                  num_target_streak: targetStreak,
                }
              )
        }
        primaryButtonProps={
          goal.status === GoalStatus.Available
            ? {
                label: formatMessage({
                  defaultMessage: "Launch app",
                  description:
                    "Goal card > evv streak > launch app button label",
                }),
                onClick: () => setOpen(true),
              }
            : undefined
        }
      />
    </>
  );
}
