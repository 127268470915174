/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/client";
import { css, useTheme } from "@emotion/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import {
  Confetti,
  DEFAULT_CONFETTI_DURATION_MS,
} from "@rewards-web/shared/components/confetti";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { usePointBalance } from "../../../../../shared/modules/point-balance";
import { PointsIcon } from "../../../../../shared/points-icon";
import { PastRedemptionsDataDocument } from "../../past-redemptions/query.generated";
import { useRedeemPointsMutation } from "../../redeem-points.generated";
import { COUNT_UPANIMATION_DURATION_IN_MS } from "../points-count-up";
import { RedeemPointsEmailWarning } from "./email-warning";
import { RedeemPointsDrawerGiftcardBody } from "./giftcard-body";
import { RedeemPointsDrawerPaycheckBody } from "./paycheck-body";

export interface RedeemPointsDrawerProps {
  open: boolean;
  onClose: () => void;
  pointsAvailableToRedeem: number;
  minimumPointsToRedeem: number;
  pointsPerDollar: number;
  redemptionMethod: RedemptionMethod;
  email: string | undefined | null;
  phoneNumber: string | undefined | null;
}

export function RedeemPointsDrawer({
  open,
  onClose,
  pointsAvailableToRedeem,
  pointsPerDollar,
  email,
  redemptionMethod,
  phoneNumber,
}: RedeemPointsDrawerProps) {
  const { formatMessage } = useFormatters();
  const apolloClient = useApolloClient();
  const pointBalance = usePointBalance({ refreshOnMount: true });
  const snackbar = useSnackbar();
  const theme = useTheme();
  const [isShowingConfetti, setIsShowingConfetti] = useState(false);
  const [isShowingEmailWarning, setIsShowingEmailWarning] = useState(false);
  const hasMissingEmail = !email || email.endsWith("@example.com");

  const confettiTimerRef = useRef<NodeJS.Timeout | null>(null);
  const onRedeemPointsTimerRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      confettiTimerRef.current && clearTimeout(confettiTimerRef.current);
      onRedeemPointsTimerRef.current &&
        clearTimeout(onRedeemPointsTimerRef.current);
    };
  }, []);
  const onModalClose = useCallback(
    ({ hasRedeemed }: { hasRedeemed: boolean }) => {
      if (hasRedeemed) {
        pointBalance.addLocalPoints(-pointsAvailableToRedeem);
        pointBalance.refreshPointBalance();
        apolloClient.refetchQueries({ include: [PastRedemptionsDataDocument] });
        confettiTimerRef.current = setTimeout(() => {
          return setIsShowingConfetti(true);
        }, COUNT_UPANIMATION_DURATION_IN_MS);
        onRedeemPointsTimerRef.current = setTimeout(
          () =>
            navigate("/my-rewards/past-redemptions", {
              state: { justRedeemed: true },
            }),
          0
        );
      }
      onClose();
    },
    [pointBalance, apolloClient, onClose, pointsAvailableToRedeem, navigate]
  );

  const [redeemPoints, { loading, data }] = useRedeemPointsMutation({
    onCompleted: () => {
      if (
        redemptionMethod === RedemptionMethod.GenericGiftCardLink &&
        hasMissingEmail
      ) {
        setIsShowingEmailWarning(true);
      } else {
        onModalClose({ hasRedeemed: true });
      }
    },
    onError: (error) => {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Redeem points drawer > error",
          defaultMessage:
            "Something went wrong when redeeming your points. Please try again later.",
        }),
      });
    },
  });

  useEffect(() => {
    if (isShowingConfetti) {
      const timer = setTimeout(
        () => setIsShowingConfetti(false),
        DEFAULT_CONFETTI_DURATION_MS
      );
      return () => clearTimeout(timer);
    }
  }, [isShowingConfetti]);

  return (
    <>
      <Confetti active={isShowingConfetti} />
      <ResponsiveDialog
        title={formatMessage({
          defaultMessage: "Redeem Points",
          description: "Redeem points drawer > title",
        })}
        open={open}
        onClose={() => onModalClose({ hasRedeemed: Boolean(data) })}
        maxWidth="400px"
        paddingBottom={0}
        backgroundColor={theme.palette.background.paper}
      >
        <div
          css={(appTheme: AppTheme) =>
            css`
              padding: ${appTheme.spacing(2.5)};
              padding-top: 0;
            `
          }
        >
          {isShowingEmailWarning ? (
            <RedeemPointsEmailWarning
              onClose={() => onModalClose({ hasRedeemed: true })}
              phoneNumber={phoneNumber}
            />
          ) : (
            <>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  text-align: center;
                `}
              >
                <div
                  css={(appTheme: AppTheme) => css`
                    text-transform: uppercase;
                    padding: ${appTheme.spacing(0.5)} ${appTheme.spacing(1.5)};
                    border-radius: 500px;
                    background-color: ${appTheme.palette.tertiary.main};
                    display: flex;
                    align-items: center;
                    gap: ${appTheme.spacing(1.25)};
                  `}
                >
                  <PointsIcon />
                  <Typography variant="caption" color="white">
                    {formatMessage(
                      {
                        defaultMessage: "{redeemable_points, number} points",
                        description: "Redeem points drawer > number of points",
                      },
                      { redeemable_points: pointsAvailableToRedeem }
                    )}
                  </Typography>
                </div>
                <Typography
                  css={(appTheme: AppTheme) =>
                    css`
                      margin: ${appTheme.spacing(2.5)} 0;
                    `
                  }
                  variant="h1"
                >
                  {formatDollars(pointsAvailableToRedeem / pointsPerDollar)}
                </Typography>

                {redemptionMethod === RedemptionMethod.GenericGiftCardLink ? (
                  <RedeemPointsDrawerGiftcardBody email={email} />
                ) : (
                  <RedeemPointsDrawerPaycheckBody />
                )}
              </div>
              <div
                css={(appTheme: AppTheme) => css`
                  display: flex;
                  align-items: center;
                  margin-top: ${appTheme.spacing(3.75)};
                `}
              >
                <Button
                  label={formatMessage({
                    defaultMessage: "Cancel",
                    description: "Redeem points drawer > cancel button",
                  })}
                  onClick={() => onClose()}
                  variant="outlined"
                />
                <Button
                  loading={loading}
                  onClick={() => {
                    redeemPoints({
                      variables: {
                        amount: pointBalance.computedBalance!,
                        redemptionMethod,
                      },
                    });
                  }}
                  color="secondary"
                  css={(appTheme: AppTheme) =>
                    css`
                      margin-left: ${appTheme.spacing(1)};
                    `
                  }
                  label={formatMessage({
                    defaultMessage: "Redeem",
                    description: "Redeem points drawer > redeem button",
                  })}
                />
              </div>
            </>
          )}
        </div>
      </ResponsiveDialog>
    </>
  );
}
