/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { Outlet } from "react-router-dom";

import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SubPageNavigationTabs } from "../../../../shared/navigation-tabs";
import { SubPageHeader } from "../../../../shared/sub-page-header";

export function OrganizationRunDrawPage() {
  const { formatMessage } = useFormatters();
  const pastDrawsEnabled = useFeatureFlag(
    "rewards-app-past-draws-tab-enabled-temp"
  );

  const mobileOrTablet = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("md")
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        & > :last-child {
          flex-grow: 1;
        }
      `}
    >
      <SubPageHeader
        pageName={formatMessage({
          description: "Draw page > sub page tabs > header",
          defaultMessage: "Draw",
        })}
        analyticsPageName="Draw"
        hideWhiteBanner={!mobileOrTablet}
      />

      <div
        css={(theme: AppTheme) => css`
          background-color: ${theme.palette.primary.main};
        `}
      >
        {pastDrawsEnabled && (
          <div
            css={(theme: AppTheme) => css`
              max-width: ${theme.maxContentWidth}px;
              margin: 0 auto;
              ${theme.breakpoints.up("lg")} {
                padding-top: ${theme.spacing(3)};
              }
            `}
          >
            <SubPageNavigationTabs
              tabs={[
                {
                  label: formatMessage({
                    description:
                      "Draw page > sub page tabs > current draw tab label",
                    defaultMessage: "Current",
                  }),
                  path: "/draw/current-draw",
                },
                {
                  label: formatMessage({
                    description: "Jobs page > past draws tab label",
                    defaultMessage: "Past draws",
                  }),
                  path: "/draw/past-draws",
                },
              ]}
              hostPageName="Draw"
            />
          </div>
        )}
      </div>

      <Outlet />
    </div>
  );
}
