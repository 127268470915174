import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetJobDetailsQueryVariables = Types.Exact<{
  jobPostingId: Types.Scalars['ID'];
}>;


export type GetJobDetailsQuery = (
  { __typename?: 'Query' }
  & { getJobPostingById?: Types.Maybe<(
    { __typename?: 'JobPosting' }
    & Pick<Types.JobPosting, 'id' | 'title' | 'ratesOfPay' | 'geography' | 'hoursRequired' | 'descriptionHTML'>
    & { activePromotion?: Types.Maybe<(
      { __typename?: 'JobPostingPromotion' }
      & Pick<Types.JobPostingPromotion, 'id'>
    )> }
  )>, countMyJobSharesThisMonth: (
    { __typename?: 'CountMyJobSharesForRange' }
    & Pick<Types.CountMyJobSharesForRange, 'numShares'>
  ), getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'maxPointsEarnedPerCandidate' | 'pointsPerDollar'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id'>
    )>, referralRewardStructure: (
      { __typename?: 'ReferralRewardStructure' }
      & Pick<Types.ReferralRewardStructure, 'id'>
      & { items: Array<(
        { __typename: 'ReferralRewardStructureJobShareItem' }
        & Pick<Types.ReferralRewardStructureJobShareItem, 'maxAwardableJobSharesPerMonth'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | (
          { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' }
          & Pick<Types.ReferralRewardStructureManualMonthlyRaffleReward, 'numTickets'>
        ) }
      ) | (
        { __typename: 'ReferralRewardStructureReferralRecruitmentItem' }
        & Pick<Types.ReferralRewardStructureReferralRecruitmentItem, 'step'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | (
          { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' }
          & Pick<Types.ReferralRewardStructureManualMonthlyRaffleReward, 'numTickets'>
        ) }
      ) | (
        { __typename: 'ReferralRewardStructureReferralRetentionItem' }
        & { duration: (
          { __typename?: 'ReferralRewardStructureReferralRetentionItemDuration' }
          & Pick<Types.ReferralRewardStructureReferralRetentionItemDuration, 'unit' | 'amount'>
        ), reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | (
          { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' }
          & Pick<Types.ReferralRewardStructureManualMonthlyRaffleReward, 'numTickets'>
        ) }
      )>, jobShareStructure: (
        { __typename?: 'ReferralRewardStructureJobShareItem' }
        & Pick<Types.ReferralRewardStructureJobShareItem, 'maxAwardableJobSharesPerMonth'>
        & { reward: (
          { __typename?: 'ReferralRewardStructurePointReward' }
          & Pick<Types.ReferralRewardStructurePointReward, 'pointValue'>
        ) | (
          { __typename?: 'ReferralRewardStructureDrawTicketReward' }
          & Pick<Types.ReferralRewardStructureDrawTicketReward, 'numTickets'>
        ) | (
          { __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward' }
          & Pick<Types.ReferralRewardStructureManualMonthlyRaffleReward, 'numTickets' | 'numWinnersSelectedPerMonth' | 'prizeText'>
        ) }
      ) }
    ) }
  ) }
);


export const GetJobDetailsDocument = gql`
    query GetJobDetails($jobPostingId: ID!) {
  getJobPostingById(jobPostingId: $jobPostingId) {
    id
    title
    ratesOfPay
    geography
    hoursRequired
    descriptionHTML
    activePromotion {
      id
    }
  }
  countMyJobSharesThisMonth {
    numShares
  }
  getMyRewardsOrganization {
    id
    maxPointsEarnedPerCandidate
    whiteLabelConfig {
      id
    }
    pointsPerDollar
    referralRewardStructure {
      id
      items {
        __typename
        ... on ReferralRewardStructureJobShareItem {
          maxAwardableJobSharesPerMonth
          reward {
            ... on ReferralRewardStructurePointReward {
              pointValue
            }
            ... on ReferralRewardStructureDrawTicketReward {
              numTickets
            }
            ... on ReferralRewardStructureManualMonthlyRaffleReward {
              numTickets
            }
          }
        }
        ... on ReferralRewardStructureReferralRecruitmentItem {
          step
          reward {
            ... on ReferralRewardStructurePointReward {
              pointValue
            }
            ... on ReferralRewardStructureDrawTicketReward {
              numTickets
            }
            ... on ReferralRewardStructureManualMonthlyRaffleReward {
              numTickets
            }
          }
        }
        ... on ReferralRewardStructureReferralRetentionItem {
          duration {
            unit
            amount
          }
          reward {
            ... on ReferralRewardStructurePointReward {
              pointValue
            }
            ... on ReferralRewardStructureDrawTicketReward {
              numTickets
            }
            ... on ReferralRewardStructureManualMonthlyRaffleReward {
              numTickets
            }
          }
        }
      }
      jobShareStructure {
        maxAwardableJobSharesPerMonth
        reward {
          ... on ReferralRewardStructurePointReward {
            pointValue
          }
          ... on ReferralRewardStructureDrawTicketReward {
            numTickets
          }
          ... on ReferralRewardStructureManualMonthlyRaffleReward {
            numTickets
            numWinnersSelectedPerMonth
            prizeText
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetJobDetailsQuery__
 *
 * To run a query within a React component, call `useGetJobDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobDetailsQuery({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *   },
 * });
 */
export function useGetJobDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetJobDetailsQuery, GetJobDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobDetailsQuery, GetJobDetailsQueryVariables>(GetJobDetailsDocument, options);
      }
export function useGetJobDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobDetailsQuery, GetJobDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobDetailsQuery, GetJobDetailsQueryVariables>(GetJobDetailsDocument, options);
        }
export type GetJobDetailsQueryHookResult = ReturnType<typeof useGetJobDetailsQuery>;
export type GetJobDetailsLazyQueryHookResult = ReturnType<typeof useGetJobDetailsLazyQuery>;
export type GetJobDetailsQueryResult = Apollo.QueryResult<GetJobDetailsQuery, GetJobDetailsQueryVariables>;