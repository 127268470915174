/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";
import { Fragment, useEffect } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { RewardOfferStructurePunchCard } from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { GoalCard } from "../../../shared/goal/goal-card";
import { GoalCardFragmentFragment } from "../../../shared/goal/goal-card/goal-card-fragment.generated";
import { ReferralsGoalCard } from "../../../shared/goal/referrals-goal-card";
import { RewardOfferActionProvider } from "../../../shared/reward-offer-action";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { DrawCard } from "./current-draw-card";
import { EVVCompletionRateOfferCard } from "./evv-completion-rate-offer-card";
import { MilestoneRewardsOfferCard } from "./milestone-rewards-offer-card";
import { PowerHoursOfferCard } from "./power-hours-offer-card";
import { PunchCardOfferCard } from "./punch-card-offer-card";
import { ReferralsRewardCard } from "./referrals-reward-card";
import { RewardsHeader } from "./rewards-header";
import { RewardsOfferCard } from "./rewards-offer-card";
import { RewardsPageDataQuery } from "./rewards-page-data.generated";
import { ScheduledRewardsCard } from "./scheduled-reward-card";
import { ShareJobsCard } from "./share-jobs-card";
import { SurveyOfferCard } from "./survey-offer-card";
import { VideoOfferCard } from "./video-offer-card";

export interface RewardsPageContentsProps {
  data: RewardsPageDataQuery;
}

type OfferType =
  | "evvCompletionRateOffers"
  | "powerHoursOffers"
  | "punchCardOffers"
  | "surveyOffers"
  | "videoOffers"
  | "milestoneRewardsOffers"
  | "otherOffers";

const OFFER_TYPES_SHOULD_ONLY_BE_ONE: ReadonlySet<OfferType> = new Set([
  "milestoneRewardsOffers" as const,
]);

type RewardOffer = RewardsPageDataQuery["listMyRewardOffers"][0];

const isVideoOffer = (offer: RewardOffer) =>
  offer.externalId?.startsWith("video-offer");

const isSurveyOffer = (offer: RewardOffer) =>
  offer.externalId?.startsWith("survey-offer");

const isPowerHoursOffer = (offer: RewardOffer) =>
  offer.externalId?.startsWith("power-hours-offer");

const isEVVCompletionRateOffer = (offer: RewardOffer) =>
  offer.externalId?.startsWith("evv-completion-rate-offer");

const isPunchCardOffer = (offer: RewardOffer) =>
  offer.structure.__typename === "RewardOfferStructurePunchCard";

const isMilestoneOffer = (offer: RewardOffer) =>
  offer.externalId?.startsWith("milestone-rewards");

export function RewardsPageContents({ data }: RewardsPageContentsProps) {
  const track = useTrack();
  const { formatMessage } = useFormatters();
  const rewardsAppDrawV2Enabled = useFeatureFlag(
    "rewards-app-tiered-prize-structure-temp"
  );

  const supportedRewardOffers = data.listMyRewardOffers.filter(
    (offer) =>
      offer.action.__typename === "RewardOfferModalAction" ||
      offer.action.__typename === "RewardOfferInAppLinkAction" ||
      offer.action.__typename === "RewardOfferExternalLinkAction"
  );

  const activeReferrals = data.getMyReferralsProgressV2?.referrals;

  const {
    evvCompletionRateOffers,
    powerHoursOffers,
    punchCardOffers,
    surveyOffers,
    videoOffers,
    milestoneRewardsOffers,
    otherOffers,
  } = supportedRewardOffers.reduce<{ [type in OfferType]: RewardOffer[] }>(
    (prev, offer) => {
      const offerType = ((): OfferType => {
        if (isEVVCompletionRateOffer(offer)) {
          return "evvCompletionRateOffers";
        }

        if (isPowerHoursOffer(offer)) {
          return "powerHoursOffers";
        }

        if (isPunchCardOffer(offer)) {
          return "punchCardOffers";
        }

        if (isSurveyOffer(offer)) {
          return "surveyOffers";
        }

        if (isVideoOffer(offer)) {
          return "videoOffers";
        }

        if (isMilestoneOffer(offer)) {
          return "milestoneRewardsOffers";
        }

        return "otherOffers";
      })();

      const existingOffersOfType = prev[offerType];
      existingOffersOfType.push(offer);

      if (
        OFFER_TYPES_SHOULD_ONLY_BE_ONE.has(offerType) &&
        existingOffersOfType.length === 2
      ) {
        reportError(
          new Error(`Did not expect more than one offer for type ${offerType}.`)
        );
      }
      return prev;
    },
    {
      evvCompletionRateOffers: [],
      powerHoursOffers: [],
      punchCardOffers: [],
      surveyOffers: [],
      videoOffers: [],
      milestoneRewardsOffers: [],
      otherOffers: [],
    }
  );

  const firstOfferCardGroups = compact([
    evvCompletionRateOffers.length > 0 && {
      key: "evv-completion-rate-cards",
      header: (
        <Typography
          variant="h3"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {formatMessage({
            defaultMessage: "Attendance compliance",
            description: "Rewards page > evv completion rate > section title",
          })}
        </Typography>
      ),
      cards: evvCompletionRateOffers.map((offer) => (
        <EVVCompletionRateOfferCard key={offer.id} offer={offer} />
      )),
    },
    powerHoursOffers.length > 0 && {
      key: "power-hours-cards",
      header: (
        <Typography
          variant="h3"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {formatMessage({
            defaultMessage: "Power hours",
            description: "Rewards page > power hours > section title",
          })}
        </Typography>
      ),
      cards: powerHoursOffers.map((offer) => (
        <RewardOfferActionProvider key={offer.id} offer={offer}>
          <PowerHoursOfferCard offer={offer} />
        </RewardOfferActionProvider>
      )),
    },
    punchCardOffers.length > 0 && {
      key: "punch-cards",
      header: (
        <Typography
          variant="h3"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {formatMessage(
            {
              defaultMessage:
                "Punch {num_punch_cards, plural, one {card} other {cards}}",
              description: "Rewards page > punch cards > section title",
            },
            {
              num_punch_cards: punchCardOffers.length,
            }
          )}
        </Typography>
      ),
      cards: punchCardOffers.map((offer) => (
        <RewardOfferActionProvider key={offer.id} offer={offer}>
          <PunchCardOfferCard
            offer={{
              ...offer,
              structure: offer.structure as Pick<
                RewardOfferStructurePunchCard,
                "numPunchesPerCard" | "pointValue" | "numDrawTickets"
              >,
            }}
          />
        </RewardOfferActionProvider>
      )),
    },
    // and current draw should be avaible
    rewardsAppDrawV2Enabled &&
      data.currentDraw &&
      data.currentDraw.status === "OPEN" && {
        key: "current-draw",
        header: null,
        cards: [
          <DrawCard
            key="jobs"
            currentDraw={data.currentDraw}
            getMyRewardsOrganization={data.getMyRewardsOrganization}
          />,
        ],
      },
    data.getMyRewardsUser.referralsEnabled &&
      !data.getMyRewardsUser.userReferralLinkDisabled &&
      !data.getMyRewardsOrganization.goalsEnabled && {
        key: "share-jobs",
        header: (
          <div
            css={(appTheme: AppTheme) => css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: ${appTheme.spacing(1.25)};
              flex-wrap: wrap;
            `}
          >
            <Typography variant="h3">
              {formatMessage({
                defaultMessage: "Share jobs",
                description: "Rewards page > Share jobs card > title",
              })}
            </Typography>
            <div
              css={(appTheme: AppTheme) => css`
                border-radius: 500px;
                padding: ${appTheme.spacing(0.5)} ${appTheme.spacing(1)};
                background-color: ${appTheme.palette.grey[100]};
                flex-shrink: 0;
              `}
            >
              <Typography
                css={css`
                  text-transform: uppercase;
                `}
                color="textPrimary"
                variant="caption"
              >
                {formatMessage(
                  {
                    defaultMessage: "up to {max_dollars} per referral 🤑",
                    description:
                      "Rewards page > Share jobs card > up to per referral",
                  },
                  {
                    max_dollars: formatDollars(
                      data.getMyRewardsOrganization
                        .maxPointsEarnedPerCandidate /
                        data.getMyRewardsOrganization.pointsPerDollar
                    ),
                  }
                )}
              </Typography>
            </div>
          </div>
        ),
        cards: [
          <ShareJobsCard
            key="jobs"
            jobShareReward={
              data.getMyRewardsOrganization.referralRewardStructure
                .jobShareStructure.reward
            }
            numJobSharesThisMonth={data.countMyJobSharesThisMonth.numShares}
            maxJobSharesPerMonth={
              data.getMyRewardsOrganization.referralRewardStructure
                .jobShareStructure.maxAwardableJobSharesPerMonth
            }
          />,
        ],
      },
  ]);

  const offerCardGroups: Array<{
    key: string;
    header: JSX.Element | null;
    cards: Array<JSX.Element>;
  }> = compact([
    ...firstOfferCardGroups,

    otherOffers.length > 0 && {
      key: "other-offers",
      header: (
        <Typography
          variant="h3"
          css={(appTheme: AppTheme) =>
            css`
              margin-bottom: ${appTheme.spacing(1.25)};
            `
          }
        >
          {firstOfferCardGroups.length > 0
            ? formatMessage({
                defaultMessage: "More rewards!",
                description:
                  "Rewards page > more rewards cards > section title",
              })
            : formatMessage({
                defaultMessage: "Ways to earn",
                description:
                  "Rewards page > more rewards cards > section title nothing above",
              })}
        </Typography>
      ),
      cards: otherOffers.map((offer) => (
        <RewardOfferActionProvider key={offer.id} offer={offer}>
          <RewardsOfferCard offer={offer} />
        </RewardOfferActionProvider>
      )),
    },

    videoOffers.length > 0 && {
      key: "video-offers",
      header: (
        <Typography
          variant="h3"
          css={(appTheme: AppTheme) =>
            css`
              margin-bottom: ${appTheme.spacing(1.25)};
            `
          }
        >
          {formatMessage({
            defaultMessage: "Watch video",
            description: "Rewards page > video cards > section title",
          })}
        </Typography>
      ),
      cards: videoOffers.map((videoOffer) => (
        <RewardOfferActionProvider key={videoOffer.id} offer={videoOffer}>
          {videoOffer.externalId ? (
            <VideoOfferCard
              videoOfferId={videoOffer.externalId.split(":")[1]}
            />
          ) : (
            <RewardsOfferCard offer={videoOffer} />
          )}
        </RewardOfferActionProvider>
      )),
    },

    surveyOffers.length > 0 && {
      key: "survey-offers",
      header: (
        <Typography variant="h3">
          {formatMessage({
            defaultMessage: "Complete survey",
            description: "Rewards page > survey offers > section title",
          })}
        </Typography>
      ),
      cards: surveyOffers.map((offer) => (
        <RewardOfferActionProvider key={offer.id} offer={offer}>
          <SurveyOfferCard offer={offer} />
        </RewardOfferActionProvider>
      )),
    },

    activeReferrals &&
      activeReferrals.length > 0 &&
      // when goals are enabled,
      // the user will see separate cards per referral.
      // this single card is for pre-goals orgs
      !data.getMyRewardsOrganization.goalsEnabled && {
        key: "active-referrals",
        header: (
          <Typography variant="h3">
            {formatMessage({
              defaultMessage: "Your referrals",
              description: "Rewards page > Referrals card > title",
            })}
          </Typography>
        ),
        cards: [
          <ReferralsRewardCard
            key="referrals-reward-card"
            activeReferrals={activeReferrals}
          />,
        ],
      },

    data.getMyEmployeePendingAndSentScheduledRecognitionPoints.length > 0 && {
      key: "scheduled-recognition",
      header: (
        <Typography variant="h3">
          {formatMessage({
            defaultMessage: "Upcoming rewards",
            description:
              "Rewards page > upcoming rewards cards > section title",
          })}
        </Typography>
      ),
      cards: data.getMyEmployeePendingAndSentScheduledRecognitionPoints.map(
        (scheduledRecognition) => (
          <ScheduledRewardsCard
            key={scheduledRecognition.id}
            scheduledRecognition={scheduledRecognition}
          />
        )
      ),
    },

    milestoneRewardsOffers.length > 0 && {
      key: "milestone-rewards",
      header: (
        <Typography variant="h3">
          {formatMessage({
            defaultMessage: "Milestone Rewards",
            description:
              "Rewards page > milestone rewards cards > section title",
          })}
        </Typography>
      ),
      cards: milestoneRewardsOffers.map((offer) => (
        <MilestoneRewardsOfferCard key={offer.id} offer={offer} />
      )),
    },
  ]);

  const offerAndGoalCardGroups = (() => {
    const {
      getMyRewardsUserAvailableGoals: availableGoals,
      getMyRewardsUserNextLockedGoals: nextLockedGoals,
      getMyRewardsUserAchievedGoalsForHomePage: achievedGoals,
      getMyReferralsProgressV2: { referrals },
      getMyRewardsOrganization: { goalsEnabled },
    } = data;

    const referralCards = (goalsEnabled
      ? referrals ?? []
      : []
    ).map((referral) => (
      <ReferralsGoalCard key={referral.id} referral={referral} />
    ));

    const renderCardsForGoal = (goal: GoalCardFragmentFragment) => [
      <GoalCard goal={goal} key={goal.id} cardContext={"home"} />,
    ];

    const renderGoalCards = (cards: JSX.Element[]) => {
      if (cards.length === 0) {
        return null;
      }

      return [
        <div
          css={(theme: AppTheme) =>
            css`
              display: flex;
              flex-direction: column;
              gap: ${theme.spacing(2)};
            `
          }
          key="goals-container"
        >
          {cards}
        </div>,
      ];
    };

    const getGoalCardGroup = ({
      key,
      cards,
    }: {
      key: string;
      cards: JSX.Element[] | null;
    }): typeof offerCardGroups[0] | null =>
      cards && {
        key,
        header: null,
        cards: cards,
      };

    const availableGoalCards = [
      ...availableGoals.flatMap(renderCardsForGoal),
      // show referral cards after available goal cards
      ...referralCards,
    ];

    const nonAvailableGoalCards = [
      ...nextLockedGoals,
      ...achievedGoals,
    ].flatMap(renderCardsForGoal);

    if (offerCardGroups.length === 0) {
      // group goals into a single card group if there are no offers
      return compact([
        getGoalCardGroup({
          key: "goal",
          cards: renderGoalCards([
            ...availableGoalCards,
            ...nonAvailableGoalCards,
          ]),
        }),
      ]);
    } else {
      return compact([
        getGoalCardGroup({
          key: "available-goals",
          cards: renderGoalCards(availableGoalCards),
        }),
        ...offerCardGroups,
        getGoalCardGroup({
          key: "non-available-goals",
          cards: renderGoalCards(nonAvailableGoalCards),
        }),
      ]);
    }
  })();

  const orderedCards = offerAndGoalCardGroups.flatMap((group) => group.cards);

  useEffect(() => {
    if (orderedCards.length === 0) {
      track("Viewed rewards page with no cards");
    }
  }, [track, orderedCards.length]);

  return (
    <>
      <ScrollToTopOnMount />
      <RewardsHeader />
      <div
        css={css`
          background-color: white;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            width: calc(100% - ${theme.spacing(4)});
            max-width: ${theme.maxContentWidth}px;
            margin: 0 auto;
            padding: ${theme.spacing(3.75, 0)};
          `}
        >
          {offerAndGoalCardGroups.map((group) => (
            <div
              key={group.key}
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(5)};
              `}
            >
              {group.header}
              {group.cards}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
