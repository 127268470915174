import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PastDrawsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PastDrawsPageDataQuery = (
  { __typename?: 'Query' }
  & { pastDraws: Array<(
    { __typename?: 'Draw' }
    & Pick<Types.Draw, 'id' | 'scheduledStartDate' | 'scheduledEndDate' | 'myNumTicketsInDraw' | 'myRewardsUserViewedWinners'>
    & { prizeStructure: (
      { __typename: 'DrawPrizeStructureSinglePrizeMultipleWinners' }
      & Pick<Types.DrawPrizeStructureSinglePrizeMultipleWinners, 'totalPrizePointValue' | 'numWinners'>
    ) | (
      { __typename: 'DrawPrizeStructureTieredPrizesMultipleWinners' }
      & Pick<Types.DrawPrizeStructureTieredPrizesMultipleWinners, 'totalPrizePointValue' | 'numWinners'>
    ), winners?: Types.Maybe<Array<(
      { __typename?: 'DrawWinner' }
      & Pick<Types.DrawWinner, 'id' | 'isMe'>
    )>> }
  )>, organization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'pointsPerDollar'>
  ) }
);


export const PastDrawsPageDataDocument = gql`
    query PastDrawsPageData {
  pastDraws: getAllPastDraws {
    id
    scheduledStartDate
    scheduledEndDate
    prizeStructure {
      __typename
      totalPrizePointValue
      numWinners
    }
    myNumTicketsInDraw
    myRewardsUserViewedWinners
    winners {
      id
      isMe
    }
  }
  organization: getMyRewardsOrganization {
    id
    pointsPerDollar
  }
}
    `;

/**
 * __usePastDrawsPageDataQuery__
 *
 * To run a query within a React component, call `usePastDrawsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastDrawsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastDrawsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePastDrawsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<PastDrawsPageDataQuery, PastDrawsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PastDrawsPageDataQuery, PastDrawsPageDataQueryVariables>(PastDrawsPageDataDocument, options);
      }
export function usePastDrawsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PastDrawsPageDataQuery, PastDrawsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PastDrawsPageDataQuery, PastDrawsPageDataQueryVariables>(PastDrawsPageDataDocument, options);
        }
export type PastDrawsPageDataQueryHookResult = ReturnType<typeof usePastDrawsPageDataQuery>;
export type PastDrawsPageDataLazyQueryHookResult = ReturnType<typeof usePastDrawsPageDataLazyQuery>;
export type PastDrawsPageDataQueryResult = Apollo.QueryResult<PastDrawsPageDataQuery, PastDrawsPageDataQueryVariables>;