import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { RewardsPageContents } from "./page-contents";
import { useRewardsPageDataQuery } from "./rewards-page-data.generated";

export function RewardsPage() {
  const { formatMessage } = useFormatters();
  const query = useRewardsPageDataQuery({
    onError: reportError,
    fetchPolicy: "cache-and-network",
  });

  if (query.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Rewards page > error loading data",
          defaultMessage: "An unexpected error occurred. Please try again",
        })}
      />
    );
  }

  if (!query.data) {
    return <PageLoadingState />;
  }

  return <RewardsPageContents data={query.data} />;
}
