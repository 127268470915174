import { decodeJWT } from "@rewards-web/shared/lib/decode-jwt";

const ACCESS_TOKEN_STORAGE_KEY = "access_token";

export function getAccessToken() {
  return (
    localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) ||
    sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
  );
}

export function saveAccessToken(accessToken: string) {
  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
}

/**
 * Save the access token to local storage, but only for the current session.
 * This is useful for temporary visit to the app, for ex: when impersonating users.
 */
export function saveAccessTokenForSession(accessToken: string) {
  sessionStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
}

export function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  sessionStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
}

export function getExistingUserId() {
  const accessToken = getAccessToken();

  if (accessToken) {
    return getUserIdFromAccessToken(accessToken);
  }

  return null;
}

export function getUserIdFromAccessToken(accessToken: string): string {
  const jwt = decodeJWT(accessToken);
  return jwt.user_id;
}
