/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { ReactNode } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { MobileTabContainer } from "@rewards-web/shared/components/mobile-tab-container";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { ComputerNavigation } from "./navigation/computer-navigation";
import { RewardsAppNavigationTabs } from "./navigation/navigation-tabs";

interface PageContainerProps {
  children: ReactNode;
  referralsEnabled: boolean;
  leaderboardEnabled: boolean;
  drawsEnabled: boolean;
}

export const COMPUTER_NAVIGATION_BAR_HEIGHT = 70;
export const MOBILE_TAB_NAVIGATION_HEIGHT = 75;

function hasMobileTabs(path: string) {
  if (matchPath("/jobs/:id", path)) {
    if (matchPath("/jobs/my-referrals", path)) {
      return true;
    }

    return false;
  }

  if (matchPath("/draw/how-it-works", path)) {
    return false;
  }

  if (matchPath("/power-hours-offer/:id", path)) {
    return false;
  }

  if (matchPath("/milestones", path)) {
    return false;
  }

  return true;
}

/**
 * - Adjust screen width for rewards app pages
 * - Renders navigation bar (top bar for computer, bottom bar for desktop)
 * - Provides page with enough top/bottom padding to make room for navigation bar
 */
export function NavigationContainer({
  children,
  referralsEnabled,
  leaderboardEnabled,
  drawsEnabled,
}: PageContainerProps): JSX.Element {
  const mobileOrTablet = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("md")
  );

  const mobileTabNavigation = mobileOrTablet;
  const computerNavigation = !mobileTabNavigation;

  const location = useLocation();

  const hideMobileTabs = !hasMobileTabs(location.pathname);

  return (
    <>
      {computerNavigation && (
        <ComputerNavigation
          height={COMPUTER_NAVIGATION_BAR_HEIGHT}
          referralsEnabled={referralsEnabled}
          leaderboardEnabled={leaderboardEnabled}
          drawsEnabled={drawsEnabled}
        />
      )}

      <div
        css={css`
          height: 100%;
        `}
      >
        <div
          id="navigation-scroll-container"
          css={css`
            position: relative;
            height: 100%;
            overflow: auto;

            // add spacing for navigation bars
            ${mobileTabNavigation &&
            !hideMobileTabs &&
            css`
              padding-bottom: calc(
                ${MOBILE_TAB_NAVIGATION_HEIGHT}px + env(safe-area-inset-bottom)
              );
            `}
            ${computerNavigation &&
            css`
              padding-top: ${COMPUTER_NAVIGATION_BAR_HEIGHT}px;
            `}
          `}
        >
          {children}
        </div>
      </div>

      {mobileTabNavigation && !hideMobileTabs && (
        <MobileTabContainer height={MOBILE_TAB_NAVIGATION_HEIGHT}>
          <RewardsAppNavigationTabs
            referralsEnabled={referralsEnabled}
            leaderboardEnabled={leaderboardEnabled}
            drawsEnabled={drawsEnabled}
          />
        </MobileTabContainer>
      )}
    </>
  );
}
