/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

export function RedeemPointsDrawerPaycheckBody() {
  const { formatMessage } = useFormatters();

  return (
    <>
      <Typography
        css={(appTheme: AppTheme) =>
          css`
            margin-bottom: ${appTheme.spacing(1.5)};
          `
        }
        variant="h5"
      >
        {formatMessage({
          defaultMessage: "Redemptions will be added to your paycheck.",
          description: "Redeem points drawer > paycheck note",
        })}
      </Typography>
      <Typography variant="body">
        {formatMessage({
          defaultMessage:
            "It can take up to 3 weeks for it to appear. Applicable taxes will apply.",
          description: "Redeem points drawer > paycheck redemptions weeks note",
        })}
      </Typography>
    </>
  );
}
