import { lighten } from "@material-ui/core";
import { Navigate, Route, Routes } from "react-router";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { Page } from "@rewards-web/shared/modules/page";
import { AppThemeProvider } from "@rewards-web/shared/style/theme";

import { AnnouncementModal } from "./announcement-modal";
import { DrawPage } from "./draw";
import { DrawHowItWorksPage } from "./draw-how-it-works";
import { DrawWinnersPage } from "./draw-winners";
import { CurrentDrawPage } from "./draw/organization-run-draw/current-draw";
import { PastDrawsPage } from "./draw/organization-run-draw/past-draws";
import { GoalTrackPage } from "./goal-tracks";
import { HelpAndSupportPage } from "./help-and-support";
import { JobDetailsPage } from "./job-details";
import { JobsPage } from "./jobs";
import { JobsPageContents } from "./jobs/jobs-page";
import { MyReferralsPageContents } from "./jobs/referrals";
import { LanguagePage } from "./language";
import { LeaderboardPage } from "./leaderboard";
import { MilestoneRewardsPage } from "./milestones";
import { NavigationContainer } from "./navigation-container";
import { NotificationsPage } from "./notifications";
import { PointsSummaryPage } from "./points-summary";
import { PastRedemptions } from "./points-summary/past-redemptions";
import { RedeemPage } from "./points-summary/redeem";
import { PowerHoursOfferPage } from "./power-hours-offer";
import { ProfilePage } from "./profile";
import { RewardsPage } from "./rewards";
import { SettingsPage } from "./settings";
import { ShiftMilestones } from "./shift-milestones";
import { SurveyPreview } from "./survey-preview";
import { useRewardsAppAuthenticatedInitializer } from "./use-authenticated-initializer";
import { VideoOfferPage } from "./video-offer";
import { WatchVideoPage } from "./watch-video";

export function Authenticated(): JSX.Element | null {
  const { formatMessage } = useFormatters();
  const surveyPreviewEnabled = useFeatureFlag("rewards-app-survey-preview");

  const initializer = useRewardsAppAuthenticatedInitializer();

  if (initializer.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Global > app could not load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (!initializer.initialized) {
    return (
      <PageLoadingState
        label={formatMessage({
          description: "Global > app main loading spinner label",
          defaultMessage: "Loading",
        })}
      />
    ); // prevent from flashing one feature to the user and flipping to another
  }

  const defaultRoute = "/home";
  const titlePostfix = initializer.data.organizationData.whiteLabelConfig
    ? initializer.data.organizationData.whiteLabelConfig.rewardsProgramShortName
    : "Caribou Rewards";

  const agencyFaviconUrl = initializer.data.organizationData.whiteLabelConfig
    ? initializer.data.organizationData.whiteLabelConfig.faviconImageUrl
    : undefined;
  const whiteLabelPrimaryColor =
    initializer.data.organizationData.whiteLabelConfig?.primaryColor;

  const whiteLabelSecondaryColor =
    initializer.data.organizationData.whiteLabelConfig?.secondaryColor;

  const organizationRunDrawsEnabled =
    initializer.data.organizationData.drawsEnabled;
  const caribouSponsoredDrawsEnabled =
    initializer.data.organizationData.hasAccessToCaribouSponsoredDraws;

  return (
    <AppThemeProvider
      renderCssBaseline
      theme={
        whiteLabelPrimaryColor && whiteLabelSecondaryColor
          ? {
              palette: {
                primary: {
                  main: whiteLabelPrimaryColor,
                },
                secondary: {
                  main: whiteLabelSecondaryColor,
                },
                background: {
                  default: lighten(whiteLabelPrimaryColor, 0.95),
                },
              },
            }
          : {} // Partial theme with nothing updated
      }
    >
      <NavigationContainer
        referralsEnabled={initializer.data.userData.referralsEnabled ?? false}
        leaderboardEnabled={
          initializer.data.organizationData.leaderboardEnabled ?? false
        }
        drawsEnabled={
          organizationRunDrawsEnabled || caribouSponsoredDrawsEnabled
        }
      >
        <Routes>
          <Route path="/" element={<Navigate to={defaultRoute} />} />

          <Route path="/login" element={<Navigate to={"/home"} />} />

          <Route path="/rewards" element={<Navigate to={"/home"} replace />} />

          <Route
            path={"/home"}
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Rewards page browser title",
                  defaultMessage: "Rewards",
                })} - ${titlePostfix}`}
                analyticsPageName="Rewards"
                faviconUrl={agencyFaviconUrl}
              >
                <AnnouncementModal />
                <RewardsPage />
              </Page>
            }
          />

          <Route
            path="/jobs"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Jobs page browser title",
                  defaultMessage: "Jobs",
                })} - ${titlePostfix}`}
                analyticsPageName="Jobs"
                faviconUrl={agencyFaviconUrl}
              >
                <JobsPage />
              </Page>
            }
          >
            <Route index element={<JobsPageContents />} />
            <Route path="my-referrals" element={<MyReferralsPageContents />} />
          </Route>

          <Route
            path="/milestones"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Milestone rewards page browser title",
                  defaultMessage: "Milestone Rewards",
                })} - ${titlePostfix}`}
                analyticsPageName="Milestone Rewards"
                faviconUrl={agencyFaviconUrl}
              >
                <MilestoneRewardsPage />
              </Page>
            }
          />

          <Route
            path="/jobs/:job_id"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Job Details page browser title",
                  defaultMessage: "Job Details",
                })} - ${titlePostfix}`}
                analyticsPageName="Job Details"
                faviconUrl={agencyFaviconUrl}
              >
                <JobDetailsPage />
              </Page>
            }
          />
          <Route
            path="/my-rewards"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Points Summary page browser title",
                  defaultMessage: "Points Summary",
                })} - ${titlePostfix}`}
                analyticsPageName="Points Summary"
                faviconUrl={agencyFaviconUrl}
              >
                <PointsSummaryPage />
              </Page>
            }
          >
            <Route
              index
              element={<Navigate to="/my-rewards/redeem" replace />}
            />
            <Route
              path="/my-rewards/redeem"
              element={
                <Page
                  browserTitle={`
                ${formatMessage({
                  description: "Global > Redeem browser title",
                  defaultMessage: "Redeem",
                })} - ${titlePostfix}`}
                  analyticsPageName="Redeem"
                  faviconUrl={agencyFaviconUrl}
                >
                  <RedeemPage />
                </Page>
              }
            />
            <Route
              path="/my-rewards/past-redemptions"
              element={
                <Page
                  browserTitle={`
                ${formatMessage({
                  description: "Global > Past redemptions browser title",
                  defaultMessage: "Past redemptions",
                })} - ${titlePostfix}`}
                  analyticsPageName="Past Rredemptions"
                  faviconUrl={agencyFaviconUrl}
                >
                  <PastRedemptions />
                </Page>
              }
            />
          </Route>
          <Route
            path="/points-summary"
            element={<Navigate to="/my-rewards" replace />}
          />
          <Route
            path="/leaderboard"
            element={
              initializer.data.organizationData.leaderboardEnabled ? (
                <Page
                  browserTitle={`
                ${formatMessage({
                  description: "Global > Leaderboard page browser title",
                  defaultMessage: "Leaderboard",
                })} - ${titlePostfix}`}
                  analyticsPageName="Leaderboard"
                  faviconUrl={agencyFaviconUrl}
                >
                  <LeaderboardPage />
                </Page>
              ) : (
                <Navigate to={defaultRoute} replace />
              )
            }
          />

          <Route
            path="/notifications"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Notifications page browser title",
                  defaultMessage: "Notifications",
                })} - ${titlePostfix}`}
                analyticsPageName="Notifications"
                faviconUrl={agencyFaviconUrl}
              >
                <AnnouncementModal />
                <NotificationsPage />
              </Page>
            }
          />

          <Route
            path="/profile"
            element={<Navigate to="/settings/profile" replace />}
          />

          <Route
            path="/settings/profile"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Profile page browser title",
                  defaultMessage: "Profile",
                })} - ${titlePostfix}`}
                analyticsPageName="Profile"
                faviconUrl={agencyFaviconUrl}
              >
                <ProfilePage />
              </Page>
            }
          />

          <Route
            path="/settings/language"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Language page browser title",
                  defaultMessage: "Language",
                })} - ${titlePostfix}`}
                analyticsPageName="Language"
                faviconUrl={agencyFaviconUrl}
              >
                <LanguagePage
                  pageType={{
                    type: "sub_page",
                    analyticsPageName: "Language",
                    backTo: "settings",
                  }}
                />
              </Page>
            }
          />

          <Route
            path="/settings/help-and-support"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Help & Support page browser title",
                  defaultMessage: "Help & Support",
                })} - ${titlePostfix}`}
                analyticsPageName="Help & Support"
                faviconUrl={agencyFaviconUrl}
              >
                <HelpAndSupportPage />
              </Page>
            }
          />
          <Route
            path="/settings"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Settings page browser title",
                  defaultMessage: "Settings",
                })} - ${titlePostfix}`}
                analyticsPageName="Settings"
                faviconUrl={agencyFaviconUrl}
              >
                <SettingsPage />
              </Page>
            }
          />

          <Route path="/power-hours" element={<Navigate replace to="/" />} />

          <Route
            path="/shift-milestones"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Shift Milestones page browser title",
                  defaultMessage: "Shift Milestones",
                })} - ${titlePostfix}`}
                analyticsPageName="Shift Milestones"
                faviconUrl={agencyFaviconUrl}
              >
                <ShiftMilestones />
              </Page>
            }
          />

          <Route
            path="/watch-video/:id"
            element={
              <Page
                browserTitle={formatMessage({
                  description: "Global > Watch video page browser title",
                  defaultMessage: "Watch Video",
                })}
                analyticsPageName="Watch Video"
                faviconUrl={agencyFaviconUrl}
              >
                <WatchVideoPage />
              </Page>
            }
          />

          <Route
            path="/video-offers/:id"
            element={
              <Page
                browserTitle={formatMessage({
                  description: "Global > Video offer page browser title",
                  defaultMessage: "Video Reward",
                })}
                analyticsPageName="Video Offer"
                faviconUrl={agencyFaviconUrl}
              >
                <VideoOfferPage />
              </Page>
            }
          />

          <Route
            path="/draw"
            element={
              initializer.data.organizationData.drawsEnabled ||
              initializer.data.organizationData
                .hasAccessToCaribouSponsoredDraws ? (
                <DrawPage />
              ) : (
                <Navigate to={defaultRoute} replace />
              )
            }
          >
            <Route
              index
              element={<Navigate to="/draw/current-draw" replace />}
            />
            <Route
              path="/draw/current-draw"
              element={
                <Page
                  browserTitle={`
                ${formatMessage({
                  description: "Global > Draw browser title",
                  defaultMessage: "Current draw",
                })} - ${titlePostfix}`}
                  analyticsPageName="Draw"
                  faviconUrl={agencyFaviconUrl}
                >
                  <CurrentDrawPage />
                </Page>
              }
            />
            <Route
              path="/draw/past-draws"
              element={
                <Page
                  browserTitle={`
                ${formatMessage({
                  description: "Global > Past draws browser title",
                  defaultMessage: "Past draws",
                })} - ${titlePostfix}`}
                  analyticsPageName="Past Draws"
                  faviconUrl={agencyFaviconUrl}
                >
                  <PastDrawsPage />
                </Page>
              }
            />
          </Route>

          <Route
            path="/draw/:draw_id/winners"
            element={
              initializer.data.organizationData.drawsEnabled ? (
                <Page
                  browserTitle={`
                ${formatMessage({
                  description: "Global > Draw winners browser title",
                  defaultMessage: "Draw Winners",
                })} - ${titlePostfix}`}
                  analyticsPageName="Draw"
                  faviconUrl={agencyFaviconUrl}
                >
                  <DrawWinnersPage />
                </Page>
              ) : (
                <Navigate to={defaultRoute} replace />
              )
            }
          />

          <Route
            path="/draw/how-it-works"
            element={
              initializer.data.organizationData.drawsEnabled ? (
                <Page
                  browserTitle={`
                ${formatMessage({
                  description: "Global > Draw > How it works browser title",
                  defaultMessage: "Draw | How It Works",
                })} - ${titlePostfix}`}
                  analyticsPageName="Draw How It Works"
                  faviconUrl={agencyFaviconUrl}
                >
                  <DrawHowItWorksPage />
                </Page>
              ) : (
                <Navigate to={defaultRoute} replace />
              )
            }
          />

          <Route
            path="/power-hours-offer/:id"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description:
                    "Global > Power Hours Offer Page > browser title",
                  defaultMessage: "Power Hours | How It Works",
                })} - ${titlePostfix}`}
                analyticsPageName="Power Hours Offer"
                faviconUrl={agencyFaviconUrl}
              >
                <PowerHoursOfferPage />
              </Page>
            }
          />

          <Route
            path="/goal-track/:track"
            element={
              <Page
                browserTitle={`
                ${formatMessage({
                  description: "Global > Goal Track Page > browser title",
                  defaultMessage: "Goal Track",
                })} - ${titlePostfix}`}
                analyticsPageName="Goal Track Page"
                faviconUrl={agencyFaviconUrl}
              >
                <GoalTrackPage />
              </Page>
            }
          />

          {surveyPreviewEnabled && (
            <Route
              path="/survey-preview/:typeform_survey_id"
              element={<SurveyPreview />}
            />
          )}
        </Routes>
      </NavigationContainer>
    </AppThemeProvider>
  );
}
