/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PastDrawsPageDataQuery } from "../past-draws-page-data.generated";
import calendarGraphicUrl from "./calendar-graphic.png";
import ticketGraphicUrl from "./ticket-graphic.png";
import winnerGraphic from "./winner-graphic.png";

type Draw = PastDrawsPageDataQuery["pastDraws"][number];

export interface PastDrawCardProps {
  draw: Pick<
    Draw,
    | "id"
    | "myNumTicketsInDraw"
    | "prizeStructure"
    | "scheduledStartDate"
    | "scheduledEndDate"
    | "winners"
    | "myRewardsUserViewedWinners"
  >;
  pointsPerDollar: number;
}

export function PastDrawCard({
  draw: {
    id: drawId,
    scheduledStartDate,
    scheduledEndDate,
    myNumTicketsInDraw: numTicketsInDraw,

    prizeStructure: {
      numWinners: numWinnersInPrizeStructure,
      totalPrizePointValue,
    },
    winners: announcedWinners,
    myRewardsUserViewedWinners: viewedWinners,
  },
  pointsPerDollar,
}: PastDrawCardProps) {
  const track = useTrack();
  const { formatMessage } = useFormatters();

  const winnersAnnounced = Array.isArray(announcedWinners);

  const numWinners = announcedWinners?.length ?? numWinnersInPrizeStructure;
  const viewedWinnersAndIsWinner =
    viewedWinners && announcedWinners?.some((winner) => winner.isMe);

  const viewWinnersButtonLabel = (() => {
    if (winnersAnnounced) {
      return formatMessage(
        {
          description: "Past draws page > past draw card > num winners",
          defaultMessage:
            "{num_winners, number} {num_winners, plural, one {Winner} other {Winners}}",
        },
        {
          num_winners: numWinners,
        }
      );
    }

    if (scheduledEndDate < new Date("2024-01-31")) {
      // Before automated selection of draw winners went live
      return formatMessage({
        description: "Past draws page > past draw card > winners not displayed",
        defaultMessage: "Winners announced outside of app",
      });
    }

    return formatMessage({
      description: "Past draws page > past draw card > winners coming soon",
      defaultMessage: "Pending",
    });
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        border: 1.5px solid rgba(204, 204, 204, 0.5);
        background: transparent;
        border-radius: 10px;
        width: 100%;
        padding: ${theme.spacing(2)};
        display: flex;
        gap: ${theme.spacing(1.5)};
        margin-bottom: ${theme.spacing(2)};
        align-items: center;

        // apply consistent wrapping
        flex-direction: column;
        ${theme.breakpoints.up("sm")} {
          flex-direction: row;
        }
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          flex-grow: 1;
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1.5)};
        `}
      >
        <img
          src={calendarGraphicUrl}
          alt="Calendar"
          css={css`
            width: 45px;
            height: 47px;
          `}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Typography
            variant="subtitle"
            color="textPrimary"
            css={css`
              text-wrap: balance;
            `}
          >
            <FormattedMessage
              description="Past draws page > past draw card > draw date"
              defaultMessage="<date>{start, date, medium}</date> - <date>{end, date, medium}</date>"
              values={{
                start: new Date(scheduledStartDate),
                end: new Date(scheduledEndDate),
                date: (nodes) => (
                  <span
                    css={css`
                      white-space: nowrap;
                    `}
                  >
                    {nodes}
                  </span>
                ),
              }}
            />
          </Typography>
          <Typography variant="footnote" color="textPrimary">
            {numTicketsInDraw > 0 ? (
              <>
                <img
                  src={ticketGraphicUrl}
                  alt="Ticket"
                  css={(theme: AppTheme) => css`
                    width: 16px;
                    margin-right: ${theme.spacing(0.5)};
                  `}
                />
                <FormattedMessage
                  description="Past draws page > past draw card > num tickets earned"
                  defaultMessage="You had {num_tickets, number} {num_tickets, plural, one {ticket} other {tickets}}"
                  values={{
                    num_tickets: numTicketsInDraw,
                  }}
                />
              </>
            ) : (
              <FormattedMessage
                description="Past draws page > past draw card > no tickets earned"
                defaultMessage="You had no tickets"
              />
            )}
          </Typography>
        </div>
      </div>
      <div
        css={(theme: AppTheme) => css`
          flex-shrink: 0;
          display: flex;
          gap: ${theme.spacing(1)};
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;

          ${theme.breakpoints.up("sm")} {
            flex-direction: column;
            align-items: center;
            flex-wrap: nowrap;
            width: auto;
          }
        `}
      >
        {viewedWinnersAndIsWinner && (
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
              justify-content: center;
              background-color: ${theme.palette.background.default};
              border-radius: 1000px;
              padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
              width: 100%;
            `}
          >
            <img
              src={winnerGraphic}
              alt="Gift box"
              css={css`
                width: 15px;
              `}
            />
            <Typography
              variant="caption"
              color="textPrimary"
              textTransform="uppercase"
              css={css`
                white-space: nowrap;
              `}
            >
              <FormattedMessage
                description="Past draws page > past draw card > you won badge"
                defaultMessage="You won!"
              />
            </Typography>
          </div>
        )}
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            track("Clicked view winners on past draw", {
              drawId,
              numTicketsInDraw,
              numWinners,
              totalPrizePointValue,
            });
          }}
          linkTo={`/draw/${drawId}/winners`}
          linkState={{ backTo: "/draw/past-draws" }}
          label={viewWinnersButtonLabel}
          disabled={!winnersAnnounced}
        />
        <Typography
          variant="footnote"
          color="grey.800"
          css={css`
            white-space: nowrap;
          `}
        >
          {totalPrizePointValue && (
            <FormattedMessage
              description="Past draws page > past draw card > total point prize value"
              defaultMessage="Total {prize_dollar_value}"
              values={{
                prize_dollar_value: formatDollars(
                  totalPrizePointValue / pointsPerDollar
                ),
              }}
            />
          )}
        </Typography>
      </div>
    </div>
  );
}
