/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";
import { StylableProps } from "@rewards-web/shared/types";

import coinPointsUrl from "../../../../coin-points.png";

export interface GoalRewardProps extends StylableProps {
  status: "achieved" | "locked" | "available";
  rewardConfig: {
    points: { numPoints?: number } & (
      | { labelType: "default" }
      | { labelType: "custom"; label: string }
    );
  };
  opacity?: string;
}

export function GoalReward({
  status,
  rewardConfig,
  opacity,
  className,
}: GoalRewardProps) {
  const { formatMessage } = useFormatters();

  if (!rewardConfig.points.numPoints) {
    return null;
  }

  return (
    <div
      className={className}
      css={(appTheme: AppTheme) => css`
        display: flex;
        column-gap: ${appTheme.spacing(1)};
        align-items: center;
        ${opacity &&
        css`
          opacity: ${opacity};
        `}
        ${status === "locked" &&
        css`
          filter: grayscale(1);
        `};
      `}
    >
      <img height="24px" src={coinPointsUrl} alt="dollar coin" css={css``} />
      <Typography variant="footnote">
        {(() => {
          switch (rewardConfig.points.labelType) {
            case "default":
              return status === "achieved"
                ? formatMessage(
                    {
                      defaultMessage: "{num_points, number} pts earned",
                      description: "Goal card > pts earned",
                    },
                    {
                      num_points: rewardConfig.points.numPoints,
                    }
                  )
                : formatMessage(
                    {
                      defaultMessage: "{num_points, number} pts",
                      description: "Goal card > pts",
                    },
                    {
                      num_points: rewardConfig.points.numPoints,
                    }
                  );
            case "custom":
              return rewardConfig.points.label;
          }
        })()}
      </Typography>
    </div>
  );
}
