/** @jsxImportSource @emotion/react */
import { css, keyframes, useTheme } from "@emotion/react";
import { getContrastRatio } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { scrollToElement } from "../../../../shared/lib/scroll-to-element";
import { PointsIcon } from "../../../../shared/points-icon";
import { InsufficientPointsDrawer } from "./insufficient-points-drawer";
import {
  COUNT_UPANIMATION_DURATION_IN_MS,
  PointsCountUp,
} from "./points-count-up";
import { RedeemPointsDrawer } from "./redeem-points-drawer";

const PROGRESS_RADIUS = 133;

interface PointsProgressProps {
  pointsAvailableToRedeem: number;
  minimumPointsToRedeem: number;
  pointsPerDollar: number;
  redemptionMethod: RedemptionMethod;
  email: string | undefined | null;
  phoneNumber: string | undefined | null;
}

const buttonAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export function PointsProgress({
  pointsAvailableToRedeem,
  pointsPerDollar,
  minimumPointsToRedeem,
  redemptionMethod,
  email,
  phoneNumber,
}: PointsProgressProps) {
  const { formatMessage } = useFormatters();
  const [isRedeemDrawerOpen, setIsRedeemDrawerOpen] = useState(false);
  const [
    isInsufficientPointsDrawerOpen,
    setIsInsufficientPointsDrawerOpen,
  ] = useState(false);
  const pastRedemptionsContainerRef = useRef<HTMLDivElement>(null);
  const canRedeem = pointsAvailableToRedeem >= minimumPointsToRedeem;
  const giftCardsRedemptionOptionsEnabled = useFeatureFlag(
    "rewards-app-gift-cards-redemption-page-temp"
  );
  // initially set
  const [progressBarPct, setProgressBarPct] = useState(0);
  const pctUntilRedemption = Math.min(
    pointsAvailableToRedeem / minimumPointsToRedeem,
    1
  );
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => setProgressBarPct(pctUntilRedemption));
  }, [pctUntilRedemption]);

  // When a user redeem their points, we scroll to the top of the page, to show the confetti
  useEffect(() => {
    const justRedeemed = location.state?.justRedeemed || false;
    if (justRedeemed) {
      if (pastRedemptionsContainerRef.current) {
        scrollToElement(pastRedemptionsContainerRef.current);
      }
    }
  }, [location]);

  const theme = useTheme();

  const redeemButtonBackgroundColor =
    getContrastRatio(
      theme.palette.secondary.main,
      theme.palette.primary.main
    ) <= 1
      ? "tertiary"
      : "secondary";

  return (
    <>
      <RedeemPointsDrawer
        phoneNumber={phoneNumber}
        open={isRedeemDrawerOpen}
        onClose={() => setIsRedeemDrawerOpen(false)}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        pointsPerDollar={pointsPerDollar}
        minimumPointsToRedeem={minimumPointsToRedeem}
        redemptionMethod={redemptionMethod}
        email={email}
      />
      <InsufficientPointsDrawer
        open={isInsufficientPointsDrawerOpen}
        onClose={() => setIsInsufficientPointsDrawerOpen(false)}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        minimumPointsToRedeem={minimumPointsToRedeem}
      />
      <div
        css={(appTheme: AppTheme) => css`
          padding: ${appTheme.spacing(5)} 0px;
          position: relative;
          display: flex;
          justify-content: center;
          background-color: ${appTheme.palette.primary.main};
          flex-direction: column;
          align-items: center;
        `}
        ref={pastRedemptionsContainerRef}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            justify-content: center;
          `}
        >
          <div
            css={css`
              position: relative;
              overflow: hidden; /* Comment this line to understand the trick */
              width: ${PROGRESS_RADIUS * 2}px;
              height: ${PROGRESS_RADIUS}px; // cuts off bottom half
            `}
          >
            <div
              role="progressbar"
              aria-valuenow={pctUntilRedemption}
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: ${PROGRESS_RADIUS * 2}px;
                height: ${PROGRESS_RADIUS * 2}px;
                border-radius: 50%;
                transform: rotate(${45 + 180 * progressBarPct}deg);
                transition: ${COUNT_UPANIMATION_DURATION_IN_MS}ms ease-out
                  transform;
                box-sizing: border-box;
                border: 26px solid rgba(255, 255, 255, 0.3);
                border-bottom-color: white;
                border-right-color: white;
              `}
            />
          </div>
          <PointsIcon
            large
            css={css`
              width: 75px;
              position: absolute;
              bottom: 0;
            `}
          />
        </div>
        <PointsCountUp points={pointsAvailableToRedeem} />
        <Typography color="white" variant="subtitle">
          {formatMessage({
            defaultMessage: "Points",
            description: "Points summary page > number of points display",
          })}
        </Typography>
        {!giftCardsRedemptionOptionsEnabled && (
          <Button
            css={(appTheme: AppTheme) => css`
              margin-top: ${appTheme.spacing(2)};
              ${canRedeem &&
              css`
                animation: ${buttonAnimation} 0.4s
                  ${COUNT_UPANIMATION_DURATION_IN_MS}ms linear;
              `}
            `}
            width="auto"
            label={
              canRedeem
                ? formatMessage(
                    {
                      defaultMessage: "Redeem {dollars}",
                      description: "Points summary page > redeem button",
                    },
                    {
                      dollars: formatDollars(
                        pointsAvailableToRedeem / pointsPerDollar
                      ),
                    }
                  )
                : formatMessage(
                    {
                      defaultMessage:
                        "{required_points, number} points until you can redeem {minimum_redeemable_dollars}",
                      description:
                        "Points summary page > disabled redeem button",
                    },
                    {
                      required_points:
                        minimumPointsToRedeem - pointsAvailableToRedeem,
                      minimum_redeemable_dollars: formatDollars(
                        minimumPointsToRedeem / pointsPerDollar
                      ),
                    }
                  )
            }
            color={canRedeem ? redeemButtonBackgroundColor : "tertiary"}
            onClick={() =>
              canRedeem
                ? setIsRedeemDrawerOpen(true)
                : setIsInsufficientPointsDrawerOpen(true)
            }
          />
        )}
      </div>
    </>
  );
}
